import * as React from 'react';
import { useEffect, useMemo } from 'react';
import { observer } from 'mobx-react';
import { emptyFragment } from '@execonline-inc/execonline-ui';
import SegmentStore from '../../../../SegmentStore';
import { SelectFormField } from '../../Types';
import { TabularOption } from '../../../ContentEmbed/Types';
import RadioFieldStore from './Store';
import RadioFieldReactions from './Reactions';
import AutoSaveReactions from '../../Reactions/AutoSaveReactions';
import { fromEmpty, Maybe } from 'maybeasy';
import { equals, first } from '@execonline-inc/collections';
import { AlreadyTranslated } from '@execonline-inc/translations';

interface Props {
  segmentStore: SegmentStore;
  uuid: string;
  selectField: SelectFormField;
  options: TabularOption[];
}
function TabularRadioQuestion({ segmentStore, uuid, selectField, options }: Props) {
  const radioFieldStore: RadioFieldStore = useMemo(() => new RadioFieldStore(uuid), [uuid]);

  useEffect(() => {
    radioFieldStore.initialize(segmentStore, selectField);
  }, []);

  const onRadioToggle = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    radioFieldStore.setValue([{ label: 'label', value: value }]);
    radioFieldStore.autoSave();
  };

  switch (radioFieldStore.state.kind) {
    case 'waiting':
    case 'initializing':
      return <RadioFieldReactions store={radioFieldStore} fireImmediately={true} />;
    case 'ready':
    case 'auto-saving':
      const valueM: Maybe<string> = fromEmpty(radioFieldStore.value)
        .andThen(first)
        .map(({ value }) => value);

      const checked = (optionValue: string): boolean =>
        valueM.map(equals(optionValue)).getOrElseValue(false);

      return selectField.label
        .map((label) => (
          <>
            <tr data-test-tabular-question={selectField.name} className="even:bg-slate-100">
              <td className="p-3">
                <AlreadyTranslated content={label} />
              </td>
              {selectField.options.map((option) => (
                <td key={option.value} className="text-center">
                  <label title={label.text}>
                    <input
                      name={selectField.name}
                      type="radio"
                      data-test-radio={selectField.name}
                      data-test-radio-value={option.value}
                      value={option.value}
                      checked={checked(option.value)}
                      onChange={onRadioToggle}
                      placeholder={label.text}
                    />
                  </label>
                </td>
              ))}
            </tr>
            <AutoSaveReactions
              store={radioFieldStore}
              segmentStore={segmentStore}
              debounceDelay={1000}
            />
            <RadioFieldReactions store={radioFieldStore} fireImmediately={true} />
          </>
        ))
        .getOrElse(emptyFragment);
  }
}

export default observer(TabularRadioQuestion);

import { observer } from 'mobx-react';
import SegmentStore from '../../../../../../SegmentStore';
import Loader from '../../../../../Loader';
import * as style from '../style.module.css';
import SegmentContent from './SegmentContent';

interface Props {
  store: SegmentStore;
}

function ContentStates({ store }: Props) {
  switch (store.state.kind) {
    case 'ready':
    case 'processing-request':
    case 'completing':
    case 'reporting-results':
    case 'auto-saving-form-fields':
    case 'schedule-session':
      return <SegmentContent store={store} segmentResource={store.state.segmentResource} />;
    case 'completing-and-advancing':
    case 'submitting-and-advancing':
    case 'previous-advancing':
    case 'advancing':
    case 'advancing-to':
    case 'error':
    case 'waiting':
    case 'loading':
    case 'loaded':
      return (
        <div className={style.content}>
          <Loader color="black" type="spin" />
        </div>
      );
  }
}

export default observer(ContentStates);

import { observer } from 'mobx-react';
import FileUploadStore from '../../../FileUploadStore';
import ProfileFormStore from '../../../ProfileFormStore';
import { profileStore } from '../../../ProfileStore';
import PhotoDisplay from './PhotoDisplay';
import PhotoUpload from './PhotoUpload';

interface Props {
  profileFormStore: ProfileFormStore;
  fileUploadStore: FileUploadStore;
}

function AvatarUploadSection({ profileFormStore, fileUploadStore }: Props) {
  const avatar = profileStore.avatar;
  switch (avatar.kind) {
    case 'avatar-link':
      return <PhotoDisplay avatarUrl={avatar.link.href} clearAvatar={profileStore.clearAvatar} />;
    case 'avatar-initials':
    case 'avatar-clearing':
      return <PhotoUpload fileUploadStore={fileUploadStore} store={profileFormStore} />;
  }
}

export default observer(AvatarUploadSection);

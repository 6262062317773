import { NonEmptyList } from 'nonempty-list';

export type Upload = FileStackUpload | FileslapUpload | SimpleFileUpload;

export interface FileStackUpload {
  key: 'filestack-upload';
  handle: string;
  filename: string;
  uploadFilePath: string;
  temporaryCdnUrl: string;
}

export interface FileslapUpload {
  key: 'fileslap-upload';
  uploadFilePath: string;
  temporaryCdnUrl: string;
}

export interface SimpleFileUpload {
  key: 'simple-upload';
  filename: string;
  uploadFilePath: string;
  temporaryCdnUrl: string;
}

export function uploadHandle(upload: Upload): string {
  switch (upload.key) {
    case 'filestack-upload':
      return upload.handle;
    case 'simple-upload':
      return upload.filename;
    case 'fileslap-upload':
      return upload.uploadFilePath;
  }
}

export function uploadFilename(upload: Upload): string {
  switch (upload.key) {
    case 'filestack-upload':
      return upload.filename;
    case 'simple-upload':
      return upload.filename;
    case 'fileslap-upload':
      return upload.uploadFilePath;
  }
}

export type Uploads = NonEmptyList<Upload>;

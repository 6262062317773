import { observer } from 'mobx-react';
import * as React from 'react';

interface Props extends React.ImgHTMLAttributes<HTMLImageElement> {}

function Image({ alt, ...others }: Props) {
  const [imageState, setImageState] = React.useState<'loading' | 'error'>('loading');
  const handleError = () => {
    console.error('[exo] Image failed to load:', others.src);
    setImageState('error');
  };

  switch (imageState) {
    case 'loading':
      return <img alt={alt} data-test-image={alt} {...others} onError={handleError} />;
    case 'error':
      return <></>;
  }
}

export default observer(Image);

import { observer } from 'mobx-react';
import { T, translation, TranslationsContext } from '../../../Translations';
import Button from '../../Form/Button';
import Label from '../../Form/Label';
import Image from '../../Image';
import * as style from './style.module.css';

interface Props {
  avatarUrl: string;
  clearAvatar: () => void;
}

function PhotoDisplay({ avatarUrl, clearAvatar }: Props) {
  return (
    <TranslationsContext.Consumer>
      {(ts) => (
        <div data-test-photo-display={true}>
          <Label className={style.label} labelText="Photo" />
          <div className={style.photoSection}>
            <div className={style.avatar}>
              <Image alt={translation('Profile Photo')(ts)} src={avatarUrl} />
            </div>
            <div className={style.fileStackSection}>
              <div className={style.photoNote} data-test-photo-note={true}>
                <T kind="Note: This photo will be visible to other ExecOnline participants." />
              </div>
              <div className={style.fileStackUploader}>
                <Button
                  type="button"
                  value="remove photo"
                  onClick={clearAvatar}
                  className={style.fileStackButton}
                  dataTestDeleteButton={true}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </TranslationsContext.Consumer>
  );
}

export default observer(PhotoDisplay);

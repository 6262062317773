import { find } from '@execonline-inc/collections';
import { assertNever } from '@kofno/piper';
import { fromEmpty, just, Maybe, nothing } from 'maybeasy';
import { action, computed, observable } from 'mobx';
import { fromArrayMaybe, NonEmptyList } from 'nonempty-list';
import { Avatar } from '../Avatar';
import { CountryOption } from '../CountryOptionsStore/Types';
import CountryRegionOptionsStore from '../CountryRegionOptionsStore';
import { CountryRegionOption } from '../CountryRegionOptionsStore/Types';
import { CurrentUserResource } from '../CurrentUser/Types';
import { findLink } from '../LinkyLinky';
import { errorAlert, FlashAlert, successAlert } from '../Notifications/Types';
import { Person, PersonStoreContract } from '../Person/types';
import { profileStore } from '../ProfileStore';
import { TimeZone } from '../ProfileStore/Types';
import { Link, ServerError } from '../Resource/Types';
import { SupportedLanguageCode } from '../SupportedLanguages/Types';
import { TPlainTextKey } from '../Translations';
import {
  checkingRequiredFields,
  countriesLoaded,
  countrySelected,
  LandingPage,
  LandingPageOption,
  LanguageOption,
  LanguageResource,
  loaded,
  loading,
  loadingCountries,
  loadingRegions,
  profileError,
  ProfileFormResource,
  ProfileFormResourceWithErrors,
  ProfileState,
  readyWithErrors,
  readyWithNoCountries,
  regionSelected,
  regionsLoaded,
  RequiredFieldErrors,
  Salutation,
  selectingCountry,
  succeed,
  updateSuccess,
  updating,
  UserInputErrorMessage,
  waiting,
} from './Types';

const findRegion = (countryRegionOptions: CountryRegionOption[], selectedRegionId: string) =>
  find((countryRegionOption) => countryRegionOption.id === selectedRegionId, countryRegionOptions);

const languageOptions = (resource: ProfileFormResource | ProfileFormResourceWithErrors) => {
  return resource.payload.availableLanguageResource.payload.map((languageResource) => ({
    label: languageResource.payload.localName,
    value: languageResource.payload.code,
  }));
};

class ProfileFormStore implements PersonStoreContract {
  @observable
  state: ProfileState;

  readonly currentUserResource: CurrentUserResource;

  constructor(currentUserResource: CurrentUserResource) {
    this.state = waiting();
    this.currentUserResource = currentUserResource;
  }

  @action
  loading = () => {
    switch (this.state.kind) {
      case 'loading':
      case 'loaded':
      case 'succeed':
      case 'update-success':
      case 'loading-countries':
      case 'ready-with-errors':
      case 'ready-with-no-countries':
      case 'waiting':
      case 'error':
      case 'checking-required-fields':
      case 'updating':
      case 'countries-loaded':
      case 'selecting-country':
      case 'country-selected':
      case 'loading-regions':
      case 'regions-loaded':
      case 'region-selected':
        this.state = loading();
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  updateSuccess = (
    profileFormResource: ProfileFormResource,
    flashMessage: Maybe<TPlainTextKey>,
    selectedLanguageCode: SupportedLanguageCode,
  ) => {
    switch (this.state.kind) {
      case 'loading':
      case 'loaded':
      case 'succeed':
        this.state = updateSuccess(profileFormResource, flashMessage, selectedLanguageCode);
        break;
      case 'update-success':
      case 'loading-countries':
      case 'ready-with-errors':
      case 'ready-with-no-countries':
      case 'waiting':
      case 'error':
      case 'checking-required-fields':
      case 'updating':
      case 'countries-loaded':
      case 'selecting-country':
      case 'country-selected':
      case 'loading-regions':
      case 'regions-loaded':
      case 'region-selected':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  loaded = (
    profileFormResource: ProfileFormResource,
    flashMessage: Maybe<TPlainTextKey>,
    selectedLanguageCode: SupportedLanguageCode,
  ) => {
    switch (this.state.kind) {
      case 'loading':
      case 'loaded':
        this.state = loaded(profileFormResource, flashMessage, selectedLanguageCode);
        break;
      case 'update-success':
      case 'loading-countries':
      case 'ready-with-errors':
      case 'ready-with-no-countries':
      case 'succeed':
      case 'waiting':
      case 'error':
      case 'checking-required-fields':
      case 'updating':
      case 'countries-loaded':
      case 'selecting-country':
      case 'country-selected':
      case 'loading-regions':
      case 'regions-loaded':
      case 'region-selected':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  succeed = (
    profileFormResource: ProfileFormResource,
    selectedLanguageCode: SupportedLanguageCode,
  ) => {
    switch (this.state.kind) {
      case 'checking-required-fields':
      case 'updating':
        this.state = succeed(profileFormResource, selectedLanguageCode);
        break;
      case 'update-success':
      case 'loading-countries':
      case 'ready-with-errors':
      case 'ready-with-no-countries':
      case 'succeed':
      case 'waiting':
      case 'error':
      case 'loading':
      case 'loaded':
      case 'countries-loaded':
      case 'selecting-country':
      case 'country-selected':
      case 'loading-regions':
      case 'regions-loaded':
      case 'region-selected':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  readyWithErrors = (
    profileFormResource: ProfileFormResourceWithErrors,
    selectedLanguageCode: SupportedLanguageCode,
    countryRegionOptionsStore: CountryRegionOptionsStore,
    errors: ReadonlyArray<ServerError>,
  ) => {
    switch (this.state.kind) {
      case 'loading':
      case 'loaded':
      case 'succeed':
        this.state = readyWithErrors(
          profileFormResource,
          selectedLanguageCode,
          countryRegionOptionsStore,
          errors,
        );
        break;
      case 'update-success':
      case 'loading-countries':
      case 'ready-with-errors':
      case 'ready-with-no-countries':
      case 'waiting':
      case 'error':
      case 'checking-required-fields':
      case 'updating':
      case 'countries-loaded':
      case 'selecting-country':
      case 'country-selected':
      case 'loading-regions':
      case 'regions-loaded':
      case 'region-selected':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  loadingCountries = (profileFormResource: ProfileFormResource) => {
    switch (this.state.kind) {
      case 'loaded':
      case 'loading-countries':
        this.state = loadingCountries(profileFormResource, this.state.selectedLanguageCode);
        break;
      case 'ready-with-errors':
      case 'ready-with-no-countries':
      case 'succeed':
      case 'loading':
      case 'update-success':
      case 'waiting':
      case 'error':
      case 'checking-required-fields':
      case 'updating':
      case 'countries-loaded':
      case 'selecting-country':
      case 'country-selected':
      case 'loading-regions':
      case 'regions-loaded':
      case 'region-selected':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  countriesLoaded = (profileFormResource: ProfileFormResource) => {
    switch (this.state.kind) {
      case 'loading-countries':
      case 'countries-loaded':
        this.state = countriesLoaded(profileFormResource, this.state.selectedLanguageCode);
        break;
      case 'update-success':
      case 'loaded':
      case 'ready-with-errors':
      case 'ready-with-no-countries':
      case 'succeed':
      case 'loading':
      case 'waiting':
      case 'error':
      case 'checking-required-fields':
      case 'updating':
      case 'selecting-country':
      case 'country-selected':
      case 'loading-regions':
      case 'regions-loaded':
      case 'region-selected':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  selectingCountry =
    (profileFormResource: Maybe<ProfileFormResource>, countries: NonEmptyList<CountryOption>) =>
    (event: React.FormEvent<HTMLSelectElement>) => {
      switch (this.state.kind) {
        case 'countries-loaded':
        case 'selecting-country':
        case 'country-selected':
        case 'ready-with-no-countries':
        case 'loading-regions':
        case 'regions-loaded':
        case 'region-selected':
        case 'update-success':
        case 'error':
          this.state = selectingCountry(
            profileFormResource,
            this.state.selectedLanguageCode,
            event,
            countries,
          );
          break;
        case 'loaded':
        case 'ready-with-errors':
        case 'succeed':
        case 'loading':
        case 'waiting':
        case 'checking-required-fields':
        case 'updating':
        case 'loading-countries':
          break;
        default:
          assertNever(this.state);
      }
    };

  @action
  countrySelected = ({
    resource,
    countries,
    selectedCountry,
  }: {
    resource: ProfileFormResource;
    countries: NonEmptyList<CountryOption>;
    selectedCountry: string;
  }) => {
    switch (this.state.kind) {
      case 'countries-loaded':
      case 'selecting-country':
      case 'country-selected':
      case 'loading-regions':
      case 'regions-loaded':
      case 'region-selected':
        this.state = countrySelected(resource, this.state.selectedLanguageCode);
        const newCountry = find((country) => country.id === selectedCountry, countries.toArray());
        this.state.profileFormResource.payload.country = newCountry;
        this.state.profileFormResource.payload.region = nothing();
        break;
      case 'update-success':
      case 'loaded':
      case 'ready-with-errors':
      case 'ready-with-no-countries':
      case 'succeed':
      case 'loading':
      case 'waiting':
      case 'error':
      case 'updating':
      case 'checking-required-fields':
      case 'loading-countries':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  loadingRegions = ({
    countryId,
    profileFormResource,
    selectedLanguageCode,
  }: {
    countryId: string;
    profileFormResource: ProfileFormResource;
    selectedLanguageCode: SupportedLanguageCode;
  }) => {
    switch (this.state.kind) {
      case 'countries-loaded':
      case 'selecting-country':
      case 'country-selected':
      case 'loading-regions':
        this.state = loadingRegions(just(countryId), profileFormResource, selectedLanguageCode);
        break;
      case 'update-success':
      case 'loaded':
      case 'ready-with-errors':
      case 'ready-with-no-countries':
      case 'succeed':
      case 'loading':
      case 'waiting':
      case 'error':
      case 'updating':
      case 'checking-required-fields':
      case 'loading-countries':
      case 'regions-loaded':
      case 'region-selected':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  regionsLoaded = (profileFormResource: ProfileFormResource) => {
    switch (this.state.kind) {
      case 'loading-regions':
      case 'regions-loaded':
        this.state = regionsLoaded(profileFormResource, this.state.selectedLanguageCode);
        break;
      case 'update-success':
      case 'loaded':
      case 'ready-with-errors':
      case 'ready-with-no-countries':
      case 'succeed':
      case 'loading':
      case 'waiting':
      case 'error':
      case 'updating':
      case 'checking-required-fields':
      case 'loading-countries':
      case 'countries-loaded':
      case 'selecting-country':
      case 'country-selected':
      case 'region-selected':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  readyWithNoCountries = (profileFormResource: ProfileFormResource) => {
    switch (this.state.kind) {
      case 'loading-regions':
      case 'loading-countries':
      case 'country-selected':
      case 'countries-loaded':
      case 'ready-with-no-countries':
        this.state = readyWithNoCountries(profileFormResource, this.state.selectedLanguageCode);
        break;
      case 'update-success':
      case 'loaded':
      case 'ready-with-errors':
      case 'ready-with-no-countries':
      case 'succeed':
      case 'loading':
      case 'waiting':
      case 'error':
      case 'updating':
      case 'checking-required-fields':
      case 'regions-loaded':
      case 'selecting-country':
      case 'region-selected':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  regionSelected = (
    profileFormResource: ProfileFormResource,
    countryRegionOptions: CountryRegionOption[],
    selectedRegion: string,
  ) => {
    switch (this.state.kind) {
      case 'regions-loaded':
      case 'region-selected':
      case 'error':
        this.state = regionSelected(profileFormResource, this.state.selectedLanguageCode);
        this.state.profileFormResource.payload.region = findRegion(
          countryRegionOptions,
          selectedRegion,
        );
        break;
      case 'update-success':
      case 'loaded':
      case 'ready-with-errors':
      case 'ready-with-no-countries':
      case 'succeed':
      case 'loading':
      case 'waiting':
      case 'updating':
      case 'checking-required-fields':
      case 'loading-countries':
      case 'countries-loaded':
      case 'selecting-country':
      case 'country-selected':
      case 'loading-regions':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  updating = () => {
    switch (this.state.kind) {
      case 'update-success':
      case 'loaded':
      case 'ready-with-errors':
      case 'ready-with-no-countries':
      case 'countries-loaded':
      case 'country-selected':
      case 'regions-loaded':
      case 'region-selected':
      case 'checking-required-fields':
      case 'error':
        this.state = updating(this.state.profileFormResource, this.state.selectedLanguageCode, []);
        break;
      case 'selecting-country':
      case 'succeed':
      case 'loading':
      case 'waiting':
      case 'updating':
      case 'loading-countries':
      case 'loading-regions':
        break;
      default:
        assertNever(this.state);
    }
  };
  @action
  checkingRequiredFields = () => {
    switch (this.state.kind) {
      case 'update-success':
      case 'loaded':
      case 'ready-with-errors':
      case 'ready-with-no-countries':
      case 'countries-loaded':
      case 'country-selected':
      case 'regions-loaded':
      case 'region-selected':
      case 'error':
        this.state = checkingRequiredFields(
          this.state.profileFormResource,
          this.state.selectedLanguageCode,
          [],
        );
        break;
      case 'selecting-country':
      case 'succeed':
      case 'loading':
      case 'waiting':
      case 'updating':
      case 'checking-required-fields':
      case 'loading-countries':
      case 'loading-regions':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  error = (error: TPlainTextKey, store: CountryRegionOptionsStore) => {
    switch (this.state.kind) {
      case 'update-success':
      case 'loaded':
      case 'ready-with-errors':
      case 'ready-with-no-countries':
      case 'countries-loaded':
      case 'country-selected':
      case 'regions-loaded':
      case 'region-selected':
      case 'succeed':
      case 'loading-countries':
      case 'loading-regions':
      case 'updating':
        this.state = profileError(
          error,
          this.state.profileFormResource,
          this.state.selectedLanguageCode,
          store,
          this.serverErrors,
          [],
        );
        break;
      case 'error':
      case 'checking-required-fields':
        this.state = profileError(
          error,
          this.state.profileFormResource,
          this.state.selectedLanguageCode,
          store,
          this.serverErrors,
          this.state.requiredFieldErrors,
        );
        break;
      case 'waiting':
      case 'loading':
      case 'selecting-country':
        break;
      default:
        assertNever(this.state);
    }
  };

  @computed
  get profileFormResource(): Maybe<ProfileFormResource> {
    switch (this.state.kind) {
      case 'update-success':
      case 'loaded':
      case 'ready-with-errors':
      case 'ready-with-no-countries':
      case 'updating':
      case 'checking-required-fields':
      case 'succeed':
      case 'loading-countries':
      case 'countries-loaded':
      case 'country-selected':
      case 'loading-regions':
      case 'regions-loaded':
      case 'region-selected':
      case 'error':
        return just(this.state.profileFormResource);
      case 'selecting-country':
        return this.state.profileFormResource;
      case 'loading':
      case 'waiting':
        return nothing();
    }
  }

  @computed
  get avatarLink(): Maybe<Link> {
    switch (this.state.kind) {
      case 'update-success':
      case 'loaded':
      case 'ready-with-errors':
      case 'ready-with-no-countries':
      case 'updating':
      case 'checking-required-fields':
      case 'succeed':
      case 'loading-countries':
      case 'countries-loaded':
      case 'country-selected':
      case 'loading-regions':
      case 'regions-loaded':
      case 'region-selected':
      case 'error':
        profileStore.avatar;
        return findLink('avatar', this.state.profileFormResource.links);
      case 'selecting-country':
      case 'loading':
      case 'waiting':
        return nothing();
    }
  }

  @computed
  get id(): number {
    return this.currentUserResource.payload.id;
  }

  @computed
  get kind(): Person['kind'] {
    return 'user';
  }

  @computed
  get notification(): Maybe<FlashAlert> {
    switch (this.state.kind) {
      case 'error':
        return just(this.state).map(errorAlert);
      case 'update-success':
      case 'loaded':
        return this.state.flashMessage.andThen(fromEmpty).map(successAlert);
      case 'succeed':
      case 'ready-with-errors':
      case 'ready-with-no-countries':
      case 'waiting':
      case 'updating':
      case 'checking-required-fields':
      case 'loading':
      case 'loading-countries':
      case 'countries-loaded':
      case 'selecting-country':
      case 'country-selected':
      case 'loading-regions':
      case 'regions-loaded':
      case 'region-selected':
        return nothing();
    }
  }

  @computed
  get firstName(): Maybe<string> {
    switch (this.state.kind) {
      case 'update-success':
      case 'loaded':
      case 'ready-with-errors':
      case 'ready-with-no-countries':
      case 'updating':
      case 'checking-required-fields':
      case 'succeed':
      case 'loading-countries':
      case 'countries-loaded':
      case 'country-selected':
      case 'loading-regions':
      case 'regions-loaded':
      case 'region-selected':
      case 'error':
        return this.state.profileFormResource.payload.firstName;
      case 'selecting-country':
      case 'loading':
      case 'waiting':
        return nothing();
    }
  }

  @computed
  get lastName(): Maybe<string> {
    switch (this.state.kind) {
      case 'update-success':
      case 'loaded':
      case 'ready-with-errors':
      case 'ready-with-no-countries':
      case 'updating':
      case 'checking-required-fields':
      case 'succeed':
      case 'loading-countries':
      case 'countries-loaded':
      case 'country-selected':
      case 'loading-regions':
      case 'regions-loaded':
      case 'region-selected':
      case 'error':
        return this.state.profileFormResource.payload.lastName;
      case 'selecting-country':
      case 'loading':
      case 'waiting':
        return nothing();
    }
  }

  @computed
  get name(): Maybe<string> {
    switch (this.state.kind) {
      case 'update-success':
      case 'loaded':
      case 'ready-with-errors':
      case 'ready-with-no-countries':
      case 'updating':
      case 'checking-required-fields':
      case 'succeed':
      case 'loading-countries':
      case 'countries-loaded':
      case 'country-selected':
      case 'loading-regions':
      case 'regions-loaded':
      case 'region-selected':
      case 'error':
        return just(`${this.firstName.getOrElseValue('')} ${this.lastName.getOrElseValue('')}`);
      case 'selecting-country':
      case 'loading':
      case 'waiting':
        return nothing();
    }
  }

  @computed
  get shortName(): Maybe<string> {
    switch (this.state.kind) {
      case 'update-success':
      case 'loaded':
      case 'ready-with-errors':
      case 'ready-with-no-countries':
      case 'updating':
      case 'checking-required-fields':
      case 'succeed':
      case 'loading-countries':
      case 'countries-loaded':
      case 'selecting-country':
      case 'country-selected':
      case 'loading-regions':
      case 'regions-loaded':
      case 'region-selected':
      case 'error':
        return profileStore.resource.andThen(({ payload }) => payload.shortName);
      case 'loading':
      case 'waiting':
        return nothing();
    }
  }

  @computed
  get initials(): Maybe<string> {
    switch (this.state.kind) {
      case 'update-success':
      case 'loaded':
      case 'ready-with-errors':
      case 'ready-with-no-countries':
      case 'updating':
      case 'checking-required-fields':
      case 'succeed':
      case 'loading-countries':
      case 'countries-loaded':
      case 'selecting-country':
      case 'country-selected':
      case 'loading-regions':
      case 'regions-loaded':
      case 'region-selected':
      case 'error':
        return profileStore.resource.andThen(({ payload }) => payload.initials);
      case 'loading':
      case 'waiting':
        return nothing();
    }
  }

  @computed
  get defaultLandingPage(): Maybe<LandingPage> {
    switch (this.state.kind) {
      case 'update-success':
      case 'loaded':
      case 'ready-with-errors':
      case 'ready-with-no-countries':
      case 'updating':
      case 'checking-required-fields':
      case 'succeed':
      case 'loading-countries':
      case 'countries-loaded':
      case 'country-selected':
      case 'loading-regions':
      case 'regions-loaded':
      case 'region-selected':
      case 'error':
        return this.state.profileFormResource.payload.defaultLandingPage;
      case 'selecting-country':
      case 'loading':
      case 'waiting':
        return nothing();
    }
  }

  @computed
  get defaultLandingPages(): LandingPageOption[] {
    switch (this.state.kind) {
      case 'update-success':
      case 'loaded':
      case 'ready-with-errors':
      case 'ready-with-no-countries':
      case 'updating':
      case 'checking-required-fields':
      case 'succeed':
      case 'loading-countries':
      case 'countries-loaded':
      case 'country-selected':
      case 'loading-regions':
      case 'regions-loaded':
      case 'region-selected':
      case 'error':
        return this.state.profileFormResource.payload.defaultLandingPages;
      case 'selecting-country':
      case 'loading':
      case 'waiting':
        return [];
    }
  }

  @computed
  get avatar(): Avatar {
    return profileStore.avatar;
  }

  @computed
  get serverErrors(): ReadonlyArray<ServerError> {
    switch (this.state.kind) {
      case 'ready-with-errors':
        return this.state.profileFormResource.errors;
      case 'error':
      case 'updating':
        return this.state.errors;
      case 'checking-required-fields':
      case 'loading':
      case 'ready-with-no-countries':
      case 'update-success':
      case 'loaded':
      case 'waiting':
      case 'succeed':
      case 'loading-countries':
      case 'countries-loaded':
      case 'selecting-country':
      case 'country-selected':
      case 'loading-regions':
      case 'regions-loaded':
      case 'region-selected':
        return [];
    }
  }

  @computed
  get requiredFieldErrors(): RequiredFieldErrors {
    switch (this.state.kind) {
      case 'checking-required-fields':
      case 'error':
        return this.state.requiredFieldErrors;
      case 'ready-with-errors':
      case 'updating':
      case 'loading':
      case 'ready-with-no-countries':
      case 'update-success':
      case 'loaded':
      case 'waiting':
      case 'succeed':
      case 'loading-countries':
      case 'countries-loaded':
      case 'selecting-country':
      case 'country-selected':
      case 'loading-regions':
      case 'regions-loaded':
      case 'region-selected':
        return [];
    }
  }

  @computed
  get division(): Maybe<string> {
    switch (this.state.kind) {
      case 'update-success':
      case 'loaded':
      case 'ready-with-errors':
      case 'ready-with-no-countries':
      case 'updating':
      case 'checking-required-fields':
      case 'succeed':
      case 'loading-countries':
      case 'countries-loaded':
      case 'country-selected':
      case 'loading-regions':
      case 'regions-loaded':
      case 'region-selected':
      case 'error':
        return this.state.profileFormResource.payload.division;
      case 'selecting-country':
      case 'loading':
      case 'waiting':
        return nothing();
    }
  }

  @computed
  get position(): Maybe<string> {
    switch (this.state.kind) {
      case 'update-success':
      case 'loaded':
      case 'ready-with-errors':
      case 'ready-with-no-countries':
      case 'updating':
      case 'checking-required-fields':
      case 'succeed':
      case 'loading-countries':
      case 'countries-loaded':
      case 'country-selected':
      case 'loading-regions':
      case 'regions-loaded':
      case 'region-selected':
      case 'error':
        return this.state.profileFormResource.payload.currentPosition;
      case 'selecting-country':
      case 'loading':
      case 'waiting':
        return nothing();
    }
  }

  @computed
  get linkedInPage(): Maybe<string> {
    switch (this.state.kind) {
      case 'update-success':
      case 'loaded':
      case 'ready-with-errors':
      case 'ready-with-no-countries':
      case 'updating':
      case 'checking-required-fields':
      case 'succeed':
      case 'loading-countries':
      case 'countries-loaded':
      case 'country-selected':
      case 'loading-regions':
      case 'regions-loaded':
      case 'region-selected':
      case 'error':
        return this.state.profileFormResource.payload.linkedInPage;
      case 'selecting-country':
      case 'loading':
      case 'waiting':
        return nothing();
    }
  }

  @computed
  get facebookPage(): Maybe<string> {
    switch (this.state.kind) {
      case 'update-success':
      case 'loaded':
      case 'ready-with-errors':
      case 'ready-with-no-countries':
      case 'updating':
      case 'checking-required-fields':
      case 'succeed':
      case 'loading-countries':
      case 'countries-loaded':
      case 'country-selected':
      case 'loading-regions':
      case 'regions-loaded':
      case 'region-selected':
      case 'error':
        return this.state.profileFormResource.payload.facebookPage;
      case 'selecting-country':
      case 'loading':
      case 'waiting':
        return nothing();
    }
  }

  @computed
  get twitterPage(): Maybe<string> {
    switch (this.state.kind) {
      case 'update-success':
      case 'loaded':
      case 'ready-with-errors':
      case 'ready-with-no-countries':
      case 'updating':
      case 'checking-required-fields':
      case 'succeed':
      case 'loading-countries':
      case 'countries-loaded':
      case 'country-selected':
      case 'loading-regions':
      case 'regions-loaded':
      case 'region-selected':
      case 'error':
        return this.state.profileFormResource.payload.twitterPage;
      case 'selecting-country':
      case 'loading':
      case 'waiting':
        return nothing();
    }
  }

  @computed
  get organization(): Maybe<string> {
    switch (this.state.kind) {
      case 'update-success':
      case 'loaded':
      case 'ready-with-errors':
      case 'ready-with-no-countries':
      case 'updating':
      case 'checking-required-fields':
      case 'succeed':
      case 'loading-countries':
      case 'countries-loaded':
      case 'country-selected':
      case 'loading-regions':
      case 'regions-loaded':
      case 'region-selected':
      case 'error':
        return this.state.profileFormResource.payload.organization;
      case 'selecting-country':
      case 'loading':
      case 'waiting':
        return nothing();
    }
  }

  @computed
  get extension(): Maybe<string> {
    switch (this.state.kind) {
      case 'update-success':
      case 'loaded':
      case 'ready-with-errors':
      case 'ready-with-no-countries':
      case 'updating':
      case 'checking-required-fields':
      case 'succeed':
      case 'loading-countries':
      case 'countries-loaded':
      case 'country-selected':
      case 'loading-regions':
      case 'regions-loaded':
      case 'region-selected':
      case 'error':
        return this.state.profileFormResource.payload.workPhone.extension;
      case 'selecting-country':
      case 'loading':
      case 'waiting':
        return nothing();
    }
  }

  @computed
  get timeZone(): Maybe<TimeZone> {
    switch (this.state.kind) {
      case 'update-success':
      case 'loaded':
      case 'ready-with-errors':
      case 'ready-with-no-countries':
      case 'updating':
      case 'checking-required-fields':
      case 'succeed':
      case 'loading-countries':
      case 'countries-loaded':
      case 'country-selected':
      case 'loading-regions':
      case 'regions-loaded':
      case 'region-selected':
      case 'error':
        return this.state.profileFormResource.payload.timeZone;
      case 'selecting-country':
      case 'loading':
      case 'waiting':
        return nothing();
    }
  }

  @computed
  get timeZoneOptions(): TimeZone[] {
    switch (this.state.kind) {
      case 'update-success':
      case 'loaded':
      case 'ready-with-errors':
      case 'ready-with-no-countries':
      case 'checking-required-fields':
      case 'updating':
      case 'succeed':
      case 'loading-countries':
      case 'countries-loaded':
      case 'country-selected':
      case 'loading-regions':
      case 'regions-loaded':
      case 'region-selected':
      case 'error':
        return this.state.profileFormResource.payload.timeZoneOptions;
      case 'selecting-country':
      case 'loading':
      case 'waiting':
        return [];
    }
  }

  @computed
  get salutationOptions(): Salutation[] {
    switch (this.state.kind) {
      case 'update-success':
      case 'loaded':
      case 'ready-with-errors':
      case 'ready-with-no-countries':
      case 'checking-required-fields':
      case 'updating':
      case 'succeed':
      case 'loading-countries':
      case 'countries-loaded':
      case 'country-selected':
      case 'loading-regions':
      case 'regions-loaded':
      case 'region-selected':
      case 'error':
        return this.state.profileFormResource.payload.salutationOptions;
      case 'selecting-country':
      case 'loading':
      case 'waiting':
        return [];
    }
  }

  @computed
  get availableLanguageOptions(): LanguageResource[] {
    switch (this.state.kind) {
      case 'update-success':
      case 'loaded':
      case 'ready-with-errors':
      case 'ready-with-no-countries':
      case 'checking-required-fields':
      case 'updating':
      case 'succeed':
      case 'loading-countries':
      case 'countries-loaded':
      case 'country-selected':
      case 'loading-regions':
      case 'regions-loaded':
      case 'region-selected':
      case 'error':
        return this.state.profileFormResource.payload.availableLanguageResource.payload;
      case 'selecting-country':
      case 'loading':
      case 'waiting':
        return [];
    }
  }

  @computed
  get languageSwitcherOptions(): Maybe<NonEmptyList<LanguageOption>> {
    switch (this.state.kind) {
      case 'update-success':
      case 'loaded':
      case 'ready-with-errors':
      case 'ready-with-no-countries':
      case 'checking-required-fields':
      case 'updating':
      case 'succeed':
      case 'loading-countries':
      case 'countries-loaded':
      case 'country-selected':
      case 'loading-regions':
      case 'regions-loaded':
      case 'region-selected':
      case 'error':
        return fromArrayMaybe(languageOptions(this.state.profileFormResource));
      case 'selecting-country':
        return this.state.profileFormResource.map(languageOptions).andThen(fromArrayMaybe);
      case 'loading':
      case 'waiting':
        return nothing();
    }
  }

  @computed
  get selectedLanguageCode(): Maybe<SupportedLanguageCode> {
    switch (this.state.kind) {
      case 'update-success':
      case 'loaded':
      case 'checking-required-fields':
      case 'updating':
      case 'succeed':
      case 'ready-with-errors':
      case 'ready-with-no-countries':
      case 'error':
      case 'loading-countries':
      case 'countries-loaded':
      case 'selecting-country':
      case 'country-selected':
      case 'loading-regions':
      case 'regions-loaded':
      case 'region-selected':
        return just(this.state.selectedLanguageCode);
      case 'loading':
      case 'waiting':
        return nothing();
    }
  }

  @computed
  get salutation(): Maybe<Salutation> {
    switch (this.state.kind) {
      case 'update-success':
      case 'loaded':
      case 'ready-with-errors':
      case 'ready-with-no-countries':
      case 'checking-required-fields':
      case 'updating':
      case 'succeed':
      case 'loading-countries':
      case 'countries-loaded':
      case 'country-selected':
      case 'loading-regions':
      case 'regions-loaded':
      case 'region-selected':
      case 'error':
        return this.state.profileFormResource.payload.salutation;
      case 'selecting-country':
      case 'loading':
      case 'waiting':
        return nothing();
    }
  }

  @computed
  get workPhone(): Maybe<string> {
    switch (this.state.kind) {
      case 'update-success':
      case 'loaded':
      case 'ready-with-errors':
      case 'ready-with-no-countries':
      case 'checking-required-fields':
      case 'updating':
      case 'succeed':
      case 'loading-countries':
      case 'countries-loaded':
      case 'country-selected':
      case 'loading-regions':
      case 'regions-loaded':
      case 'region-selected':
      case 'error':
        return this.state.profileFormResource.payload.workPhone.number;
      case 'selecting-country':
      case 'loading':
      case 'waiting':
        return nothing();
    }
  }

  @computed
  get cellPhone(): Maybe<string> {
    switch (this.state.kind) {
      case 'update-success':
      case 'loaded':
      case 'ready-with-errors':
      case 'ready-with-no-countries':
      case 'checking-required-fields':
      case 'updating':
      case 'succeed':
      case 'loading-countries':
      case 'countries-loaded':
      case 'country-selected':
      case 'loading-regions':
      case 'regions-loaded':
      case 'region-selected':
      case 'error':
        return this.state.profileFormResource.payload.cellPhone;
      case 'selecting-country':
      case 'loading':
      case 'waiting':
        return nothing();
    }
  }

  @computed
  get country(): Maybe<CountryOption> {
    switch (this.state.kind) {
      case 'update-success':
      case 'loaded':
      case 'ready-with-errors':
      case 'ready-with-no-countries':
      case 'checking-required-fields':
      case 'updating':
      case 'succeed':
      case 'loading-countries':
      case 'countries-loaded':
      case 'country-selected':
      case 'loading-regions':
      case 'regions-loaded':
      case 'region-selected':
      case 'error':
        return this.state.profileFormResource.payload.country;
      case 'selecting-country':
      case 'loading':
      case 'waiting':
        return nothing();
    }
  }

  @computed
  get countryId(): Maybe<string> {
    switch (this.state.kind) {
      case 'update-success':
      case 'loaded':
      case 'ready-with-errors':
      case 'ready-with-no-countries':
      case 'checking-required-fields':
      case 'updating':
      case 'succeed':
      case 'loading-countries':
      case 'countries-loaded':
      case 'country-selected':
      case 'loading-regions':
      case 'regions-loaded':
      case 'region-selected':
      case 'error':
        return this.state.profileFormResource.payload.country.map((c) => c.id);
      case 'selecting-country':
      case 'loading':
      case 'waiting':
        return nothing();
    }
  }

  @computed
  get region(): Maybe<CountryRegionOption> {
    switch (this.state.kind) {
      case 'update-success':
      case 'loaded':
      case 'ready-with-errors':
      case 'ready-with-no-countries':
      case 'checking-required-fields':
      case 'updating':
      case 'succeed':
      case 'loading-countries':
      case 'countries-loaded':
      case 'country-selected':
      case 'loading-regions':
      case 'regions-loaded':
      case 'region-selected':
      case 'error':
        return this.state.profileFormResource.payload.region;
      case 'selecting-country':
      case 'loading':
      case 'waiting':
        return nothing();
    }
  }

  @computed
  get email(): Maybe<string> {
    switch (this.state.kind) {
      case 'update-success':
      case 'loaded':
      case 'ready-with-errors':
      case 'ready-with-no-countries':
      case 'checking-required-fields':
      case 'updating':
      case 'succeed':
      case 'loading-countries':
      case 'countries-loaded':
      case 'country-selected':
      case 'loading-regions':
      case 'regions-loaded':
      case 'region-selected':
      case 'error':
        return profileStore.resource.map(({ payload }) => payload.email);
      case 'selecting-country':
      case 'loading':
      case 'waiting':
        return nothing();
    }
  }

  @computed
  get links(): ReadonlyArray<Link> {
    switch (this.state.kind) {
      case 'update-success':
      case 'loaded':
      case 'ready-with-errors':
      case 'ready-with-no-countries':
      case 'loading-countries':
      case 'countries-loaded':
      case 'country-selected':
      case 'loading-regions':
      case 'regions-loaded':
      case 'region-selected':
      case 'checking-required-fields':
      case 'updating':
      case 'succeed':
      case 'error':
        return this.state.profileFormResource.links;
      case 'selecting-country':
      case 'loading':
      case 'waiting':
        return [];
    }
  }

  @computed
  get updateLink(): ReadonlyArray<Link> {
    switch (this.state.kind) {
      case 'update-success':
      case 'loaded':
      case 'ready-with-errors':
      case 'ready-with-no-countries':
      case 'loading-countries':
      case 'countries-loaded':
      case 'country-selected':
      case 'loading-regions':
      case 'regions-loaded':
      case 'region-selected':
      case 'checking-required-fields':
      case 'updating':
      case 'succeed':
      case 'error':
        return this.state.profileFormResource.links;
      case 'selecting-country':
        return this.state.profileFormResource.map((resource) => resource.links).getOrElseValue([]);
      case 'loading':
      case 'waiting':
        return [];
    }
  }

  @action
  setFirstName = (firstName: string) => {
    switch (this.state.kind) {
      case 'update-success':
      case 'loaded':
      case 'ready-with-errors':
      case 'ready-with-no-countries':
      case 'countries-loaded':
      case 'country-selected':
      case 'loading-regions':
      case 'regions-loaded':
      case 'region-selected':
      case 'error':
        this.state.profileFormResource.payload.firstName = fromEmpty(firstName);
        break;
      case 'succeed':
      case 'loading-countries':
      case 'selecting-country':
      case 'checking-required-fields':
      case 'updating':
      case 'loading':
      case 'waiting':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  setLastName = (lastName: string) => {
    switch (this.state.kind) {
      case 'update-success':
      case 'loaded':
      case 'ready-with-errors':
      case 'ready-with-no-countries':
      case 'countries-loaded':
      case 'country-selected':
      case 'loading-regions':
      case 'regions-loaded':
      case 'region-selected':
      case 'error':
        this.state.profileFormResource.payload.lastName = fromEmpty(lastName);
        break;
      case 'succeed':
      case 'checking-required-fields':
      case 'updating':
      case 'loading':
      case 'waiting':
      case 'loading-countries':
      case 'selecting-country':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  setSalutation = (salutation: Maybe<Salutation>) => {
    switch (this.state.kind) {
      case 'update-success':
      case 'loaded':
      case 'ready-with-errors':
      case 'ready-with-no-countries':
      case 'countries-loaded':
      case 'country-selected':
      case 'loading-regions':
      case 'regions-loaded':
      case 'region-selected':
      case 'error':
        this.state.profileFormResource.payload.salutation = salutation;
        break;
      case 'succeed':
      case 'checking-required-fields':
      case 'updating':
      case 'loading':
      case 'waiting':
      case 'loading-countries':
      case 'selecting-country':
        break;
      default:
        assertNever(this.state);
    }
  };
  setDefaultLandingPage = (defaultLandingPage: Maybe<LandingPage>) => {
    switch (this.state.kind) {
      case 'update-success':
      case 'loaded':
      case 'ready-with-errors':
      case 'ready-with-no-countries':
      case 'loading-countries':
      case 'countries-loaded':
      case 'country-selected':
      case 'loading-regions':
      case 'regions-loaded':
      case 'region-selected':
      case 'error':
      case 'succeed':
      case 'checking-required-fields':
      case 'updating':
        this.state.profileFormResource.payload.defaultLandingPage = defaultLandingPage;
        break;
      case 'loading':
      case 'waiting':
      case 'selecting-country':
        break;
      default:
        assertNever(this.state);
    }
  };
  @action
  setDivision = (division: string) => {
    switch (this.state.kind) {
      case 'update-success':
      case 'loaded':
      case 'ready-with-errors':
      case 'ready-with-no-countries':
      case 'countries-loaded':
      case 'country-selected':
      case 'loading-regions':
      case 'regions-loaded':
      case 'region-selected':
      case 'error':
        this.state.profileFormResource.payload.division = fromEmpty(division);
        break;
      case 'succeed':
      case 'checking-required-fields':
      case 'updating':
      case 'loading':
      case 'waiting':
      case 'loading-countries':
      case 'selecting-country':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  setCellPhone = (cellPhone: string) => {
    switch (this.state.kind) {
      case 'update-success':
      case 'loaded':
      case 'ready-with-errors':
      case 'ready-with-no-countries':
      case 'countries-loaded':
      case 'country-selected':
      case 'loading-regions':
      case 'regions-loaded':
      case 'region-selected':
      case 'error':
        this.state.profileFormResource.payload.cellPhone = fromEmpty(cellPhone);
        break;
      case 'succeed':
      case 'checking-required-fields':
      case 'updating':
      case 'loading':
      case 'waiting':
      case 'loading-countries':
      case 'selecting-country':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  setServerErrors = (errors: ServerError[]) => {
    switch (this.state.kind) {
      case 'ready-with-errors':
      case 'updating':
        this.state.errors = errors;
        break;
      case 'loading':
      case 'checking-required-fields':
      case 'ready-with-no-countries':
      case 'update-success':
      case 'loaded':
      case 'error':
      case 'waiting':
      case 'succeed':
      case 'loading-countries':
      case 'countries-loaded':
      case 'selecting-country':
      case 'country-selected':
      case 'loading-regions':
      case 'regions-loaded':
      case 'region-selected':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  setRequiredFieldErrors = (errorMessages: Maybe<UserInputErrorMessage>[]) => {
    switch (this.state.kind) {
      case 'checking-required-fields':
        this.state.requiredFieldErrors = errorMessages;
        break;
      case 'ready-with-errors':
      case 'updating':
      case 'loading':
      case 'ready-with-no-countries':
      case 'update-success':
      case 'loaded':
      case 'error':
      case 'waiting':
      case 'succeed':
      case 'loading-countries':
      case 'countries-loaded':
      case 'selecting-country':
      case 'country-selected':
      case 'loading-regions':
      case 'regions-loaded':
      case 'region-selected':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  setWorkNumber = (number: string) => {
    switch (this.state.kind) {
      case 'update-success':
      case 'loaded':
      case 'ready-with-errors':
      case 'ready-with-no-countries':
      case 'countries-loaded':
      case 'country-selected':
      case 'loading-regions':
      case 'regions-loaded':
      case 'region-selected':
      case 'error':
        this.state.profileFormResource.payload.workPhone.number = fromEmpty(number);
        break;
      case 'succeed':
      case 'checking-required-fields':
      case 'updating':
      case 'loading':
      case 'waiting':
      case 'loading-countries':
      case 'selecting-country':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  setLinkedInPage = (linkedInPage: string) => {
    switch (this.state.kind) {
      case 'update-success':
      case 'loaded':
      case 'ready-with-errors':
      case 'ready-with-no-countries':
      case 'countries-loaded':
      case 'country-selected':
      case 'loading-regions':
      case 'regions-loaded':
      case 'region-selected':
      case 'error':
        this.state.profileFormResource.payload.linkedInPage = fromEmpty(linkedInPage);
        break;
      case 'succeed':
      case 'checking-required-fields':
      case 'updating':
      case 'loading':
      case 'waiting':
      case 'loading-countries':
      case 'selecting-country':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  setFacebookPage = (facebookPage: string) => {
    switch (this.state.kind) {
      case 'update-success':
      case 'loaded':
      case 'ready-with-errors':
      case 'ready-with-no-countries':
      case 'countries-loaded':
      case 'country-selected':
      case 'loading-regions':
      case 'regions-loaded':
      case 'region-selected':
      case 'error':
        this.state.profileFormResource.payload.facebookPage = fromEmpty(facebookPage);
        break;
      case 'succeed':
      case 'checking-required-fields':
      case 'updating':
      case 'loading':
      case 'waiting':
      case 'loading-countries':
      case 'selecting-country':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  setTwitterPage = (twitterPage: string) => {
    switch (this.state.kind) {
      case 'update-success':
      case 'loaded':
      case 'ready-with-errors':
      case 'ready-with-no-countries':
      case 'countries-loaded':
      case 'country-selected':
      case 'loading-regions':
      case 'regions-loaded':
      case 'region-selected':
      case 'error':
        this.state.profileFormResource.payload.twitterPage = fromEmpty(twitterPage);
        break;
      case 'succeed':
      case 'checking-required-fields':
      case 'updating':
      case 'loading':
      case 'waiting':
      case 'loading-countries':
      case 'selecting-country':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  setExtension = (extension: string) => {
    switch (this.state.kind) {
      case 'update-success':
      case 'loaded':
      case 'ready-with-errors':
      case 'ready-with-no-countries':
      case 'countries-loaded':
      case 'country-selected':
      case 'loading-regions':
      case 'regions-loaded':
      case 'region-selected':
      case 'error':
        this.state.profileFormResource.payload.workPhone.extension = fromEmpty(extension);
        break;
      case 'succeed':
      case 'checking-required-fields':
      case 'updating':
      case 'loading':
      case 'waiting':
      case 'loading-countries':
      case 'selecting-country':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  setCurrentPosition = (currentPosition: string) => {
    switch (this.state.kind) {
      case 'update-success':
      case 'loaded':
      case 'ready-with-errors':
      case 'ready-with-no-countries':
      case 'countries-loaded':
      case 'country-selected':
      case 'loading-regions':
      case 'regions-loaded':
      case 'region-selected':
      case 'error':
        this.state.profileFormResource.payload.currentPosition = fromEmpty(currentPosition);
        break;
      case 'succeed':
      case 'checking-required-fields':
      case 'updating':
      case 'loading':
      case 'waiting':
      case 'loading-countries':
      case 'selecting-country':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  setTimeZone = (timeZone: Maybe<TimeZone>) => {
    switch (this.state.kind) {
      case 'update-success':
      case 'loaded':
      case 'ready-with-errors':
      case 'ready-with-no-countries':
      case 'countries-loaded':
      case 'country-selected':
      case 'loading-regions':
      case 'regions-loaded':
      case 'region-selected':
      case 'error':
        this.state.profileFormResource.payload.timeZone = timeZone;
        break;
      case 'succeed':
      case 'checking-required-fields':
      case 'updating':
      case 'loading':
      case 'waiting':
      case 'loading-countries':
      case 'selecting-country':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  setLanguageCode = (selectedLanguageCode: SupportedLanguageCode) => {
    switch (this.state.kind) {
      case 'update-success':
      case 'loaded':
      case 'ready-with-errors':
      case 'ready-with-no-countries':
      case 'countries-loaded':
      case 'country-selected':
      case 'loading-regions':
      case 'regions-loaded':
      case 'region-selected':
      case 'error':
        this.state = updateSuccess(this.state.profileFormResource, nothing(), selectedLanguageCode);
        break;
      case 'succeed':
      case 'checking-required-fields':
      case 'updating':
      case 'loading':
      case 'waiting':
      case 'loading-countries':
      case 'selecting-country':
        break;
      default:
        assertNever(this.state);
    }
  };
}

export default ProfileFormStore;

import { assertNever } from '@kofno/piper';
import { just, Maybe, nothing } from 'maybeasy';
import { action, computed, observable } from 'mobx';
import { Avatar, avatarFrom, AvatarLink } from '../Avatar';
import { CountryOption } from '../CountryOptionsStore/Types';
import { CountryRegionOption } from '../CountryRegionOptionsStore/Types';
import { Errored } from '../CurrentUser/Context/WhenUserDataLoaded/ContingentStoreCommonStates';
import {
  ContingencyResolution,
  ContingentStore,
} from '../CurrentUser/Context/WhenUserDataLoaded/Types';
import { CurrentUserResource } from '../CurrentUser/Types';
import { findLink } from '../LinkyLinky';
import { PersonStoreContract } from '../Person/types';
import { State } from './States';
import { ProfileResource } from './Types';

class ProfileStore implements ContingentStore {
  @observable state: State = { kind: 'waiting' };
  @observable avatar: Avatar = { kind: 'avatar-initials', initials: 'ME' };

  @action reset = (): void => {
    switch (this.state.kind) {
      case 'waiting':
      case 'ready':
      case 'errored':
      case 'loading':
      case 'refreshing':
        this.state = { kind: 'waiting' };
        break;
      default:
        assertNever(this.state);
    }
  };

  @action loading = (currentUserResource: CurrentUserResource): void => {
    switch (this.state.kind) {
      case 'waiting':
        this.state = { kind: 'loading', currentUserResource };
        break;
      case 'ready':
      case 'errored':
      case 'loading':
      case 'refreshing':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action refreshing = (currentUserResource: CurrentUserResource): void => {
    switch (this.state.kind) {
      case 'ready':
        this.state = { kind: 'refreshing', resource: this.state.resource, currentUserResource };
        break;
      case 'waiting':
      case 'loading':
      case 'refreshing':
      case 'errored':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action ready = (resource: ProfileResource): void => {
    switch (this.state.kind) {
      case 'loading':
      case 'refreshing':
        this.state = { kind: 'ready', resource };
        this.avatar = avatarFrom(
          findLink('avatar', this.state.resource.links),
          this.state.resource.payload.initials,
          'ME',
        );
        break;
      case 'waiting':
      case 'ready':
      case 'errored':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action errored = (error: Errored['error']): void => {
    switch (this.state.kind) {
      case 'loading':
      case 'refreshing':
        this.state = { kind: 'errored', error };
        break;
      case 'waiting':
      case 'ready':
      case 'errored':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action setAvatar = (avatar: AvatarLink): void => {
    switch (this.state.kind) {
      case 'ready':
        this.avatar = avatar;
        break;
      case 'waiting':
      case 'loading':
      case 'refreshing':
      case 'errored':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action clearAvatar = (): void => {
    switch (this.state.kind) {
      case 'ready':
        if (this.avatar.kind === 'avatar-link') {
          this.avatar = { kind: 'avatar-clearing', avatarLink: this.avatar };
        }

        break;
      case 'waiting':
      case 'loading':
      case 'refreshing':
      case 'errored':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action avatarCleared = (): void => {
    switch (this.state.kind) {
      case 'ready':
        this.avatar = {
          kind: 'avatar-initials',
          initials: this.state.resource.payload.initials.getOrElseValue('ME'),
        };
        break;
      case 'waiting':
      case 'loading':
      case 'refreshing':
      case 'errored':
        break;
      default:
        assertNever(this.state);
    }
  };

  @computed get resolution(): ContingencyResolution {
    switch (this.state.kind) {
      case 'ready':
      case 'errored':
        return 'finished';
      case 'waiting':
        return 'waiting';
      case 'loading':
        return 'loading';
      case 'refreshing':
        return 'refreshing';
    }
  }

  @computed get resource(): Maybe<ProfileResource> {
    switch (this.state.kind) {
      case 'ready':
      case 'refreshing':
        return just(this.state.resource);
      case 'waiting':
      case 'loading':
      case 'errored':
        return nothing();
    }
  }

  @computed get personStoreContract(): Maybe<PersonStoreContract> {
    return this.resource.map(({ payload, links }) => ({
      id: payload.userId,
      kind: 'user' as const,
      name: payload.name,
      photo: findLink('avatar', links),
      initials: payload.initials,
      shortName: payload.shortName,
      email: just(payload.email),
      avatar: this.avatar,
      linkedInPage: nothing<string>(),
      facebookPage: nothing<string>(),
      twitterPage: nothing<string>(),
      position: nothing<string>(),
      division: nothing<string>(),
      workPhone: nothing<string>(),
      extension: nothing<string>(),
      cellPhone: nothing<string>(),
      organization: nothing<string>(),
      country: nothing<CountryOption>(),
      region: nothing<CountryRegionOption>(),
    }));
  }
}

export type { ProfileStore };

export const profileStore = new ProfileStore();

import { ButtonProps, emptyFragment } from '@execonline-inc/execonline-ui';
import { whenGt } from '@execonline-inc/numbers';
import { just } from 'maybeasy';
import { observer } from 'mobx-react';
import { useProgramModuleProgressContext } from '../../../../../ProgramModuleProgress';
import SegmentStore from '../../../../../SegmentStore';
import { AdvanceButton } from '../../../../Segment/SegmentNavigation/Common';

interface Props extends ButtonProps {
  store: SegmentStore;
}

function ReadyButton({ store, ...others }: Props) {
  const programProgress = useProgramModuleProgressContext();

  return programProgress
    .andThen((progress) => just(progress.payload.currentPosition))
    .andThen(whenGt(1))
    .map(() => <AdvanceButton {...others} onClick={store.previousAdvance} />)
    .getOrElse(emptyFragment);
}

export default observer(ReadyButton);

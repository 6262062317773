import { observer } from 'mobx-react';
import FileUploadStore from '../../../FileUploadStore';
import ProfileFormStore from '../../../ProfileFormStore';
import { T } from '../../../Translations';
import ProfileDefaultIcon from '../../Cutesies/ProfileDefaultIcon';
import FileUpload from '../../FileUpload';
import UploadInProgress from '../../FileUpload/UploadInProgress';
import Label from '../../Form/Label';
import * as style from './style.module.css';

interface Props {
  store: ProfileFormStore;
  fileUploadStore: FileUploadStore;
}

function PhotoUpload({ store, fileUploadStore }: Props) {
  return (
    <div data-test-photo-upload={true}>
      <Label className={style.label} labelText="Photo" />
      <div className={style.photoSection}>
        <div className={style.avatar} data-test-default-avatar={true}>
          <ProfileDefaultIcon />
        </div>
        <div className={style.fileStackSection}>
          <div className={style.photoNote} data-test-photo-note={true}>
            <T kind="Adding a photo enhances your ExecOnline experience ..." />
          </div>
          <div className={style.fileStackUploader}>
            <FileUpload buttonClass={style.fileStackButton} formStore={store} />
            <div className={style.inProgressWrapper}>
              <UploadInProgress store={fileUploadStore} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default observer(PhotoUpload);

import { observer } from 'mobx-react';
import React from 'react';
import { CurrentUserContext } from '..';
import ErrorBoundary from '../../../ErrorHandling/ErrorBoundary';
import Children from './Children';
import { onError } from './Functions';

interface Props {
  children: React.ComponentProps<typeof Children>['children'];
}

function WithCurrentUser({ children }: Props) {
  const currentUserResourceState = React.useContext(CurrentUserContext);

  return (
    <ErrorBoundary onError={onError}>
      <Children ctx={currentUserResourceState} children={children} />
    </ErrorBoundary>
  );
}

export default observer(WithCurrentUser);

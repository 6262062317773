import { stringLiteral } from '@execonline-inc/decoders';
import { log, warn } from '@execonline-inc/logging';
import { always, assertNever } from '@kofno/piper';
import Decoder, { at, field, string, succeed } from 'jsonous';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { Task } from 'taskarian';
import { AppyError, putToApi } from '../../Appy';
import { currentUserStore } from '../../CurrentUser/Store';
import { useFindLinkByT } from '../../Fetch';
import { MissingLinkError } from '../../Links';
import { ProfileStore, profileStore } from '../../ProfileStore';
import { Link } from '../../Resource/Types';
import { liftToTask } from '../../TaskExt';
import { TPlainTextKey } from '../../Translations';
import { useTranslationsContext } from '../../Translations/UseTranslationsContext';
import AsModal from '../Modal/AsModal';
import { useOpenable } from '../Openable';

interface Props {
  src: string;
  buttonClass: string;
  buttonText: TPlainTextKey;
}

interface FileslapUploadSuccess {
  event: 'avatar-uploaded';
  upload_key: string;
  avatar_url: string;
}

type FileslapMessage = FileslapUploadSuccess;

interface UnexpectedMessage {
  kind: 'unexpected-message';
  message: unknown;
}

function currentUserLinks() {
  const state = currentUserStore.state;
  switch (state.kind) {
    case 'anonymous':
    case 'errored':
    case 'loading':
    case 'waiting':
      return [];
    case 'logging-out':
    case 'ready':
    case 'refreshing':
      return state.currentUserResource.links;
    default:
      assertNever(state);
  }
}

function addLink(
  link: Task<MissingLinkError, Link>,
): (data: FileslapUploadSuccess) => Task<MissingLinkError, FileslapUploadSuccess & { link: Link }>;
function addLink(
  link: Task<MissingLinkError, Link>,
  data: FileslapUploadSuccess,
): Task<never, FileslapUploadSuccess & { link: Link }>;
function addLink(link: Task<MissingLinkError, Link>, data?: FileslapUploadSuccess) {
  const doit = (data: FileslapUploadSuccess) => {
    return link.map((link) => ({ ...data, link }));
  };
  return typeof data === 'undefined' ? doit : doit(data);
}

function updateAvatar(data: FileslapUploadSuccess & { link: Link }) {
  const { upload_key, link } = data;
  return putToApi({ key: upload_key }, link).map(always(data));
}

function isFileslapMessage(event: MessageEvent<unknown>): boolean {
  return (
    event.origin === 'http://fileslap.execonline.localhost' ||
    event.origin === 'https://fileslap.execonline.com' ||
    event.origin === 'https://fileslap.staging.execonline.com'
  );
}

function messageFormatMismatch(msg: string): UnexpectedMessage {
  warn('Error decoding Fileslap message:', msg);
  return { kind: 'unexpected-message', message: msg };
}

function setAvatar(
  store: ProfileStore,
): (data: FileslapUploadSuccess) => Task<never, FileslapUploadSuccess>;
function setAvatar(
  store: ProfileStore,
  data: FileslapUploadSuccess,
): Task<never, FileslapUploadSuccess>;
function setAvatar(store: ProfileStore, data?: FileslapUploadSuccess) {
  const doit = (data: FileslapUploadSuccess): Task<never, FileslapUploadSuccess> => {
    const { avatar_url } = data;
    store.setAvatar({
      kind: 'avatar-link',
      link: {
        href: avatar_url,
        rel: 'avatar',
        method: 'get',
        type: 'image/png',
      },
    });
    return Task.succeed(data);
  };

  return typeof data === 'undefined' ? doit : doit(data);
}

function decodeEvent(
  data: unknown,
): Task<UnexpectedMessage | MissingLinkError | AppyError, FileslapUploadSuccess> {
  return fileslapEventDataDecoder
    .decodeAny(data)
    .mapError<UnexpectedMessage | MissingLinkError | AppyError>(messageFormatMismatch)
    .cata<
      Task<UnexpectedMessage | MissingLinkError | AppyError, FileslapUploadSuccess>
    >(liftToTask());
}

const fileslapEventDataDecoder: Decoder<FileslapMessage> = succeed({})
  .assign('event', field('event', stringLiteral('avatar-uploaded')))
  .assign('upload_key', at(['data', 'upload', 'key'], string))
  .assign('avatar_url', at(['data', 'avatar_url'], string));

function FileslapFileUpload({ buttonText, buttonClass, src }: Props) {
  const { translate } = useTranslationsContext();
  const link = useFindLinkByT({ rel: 'avatar-update', method: 'put' }, currentUserLinks());
  const { openableState, close, open } = useOpenable();

  useEffect(() => {
    log('Adding event listener for Fileslap');
    const listener = (event: MessageEvent<unknown>) => {
      if (!isFileslapMessage(event)) return;

      decodeEvent(event.data)
        .andThen(addLink(link))
        .andThen(updateAvatar)
        .andThen(setAvatar(profileStore))
        .elseDo((err) => warn('Error updating avatar:', err))
        .fork(close, close);
    };

    window.addEventListener('message', listener);

    return () => {
      if (listener) {
        window.removeEventListener('message', listener, false);
      }
    };
  });

  return (
    <>
      <button type="button" className={buttonClass} onClick={open}>
        {buttonText}
      </button>
      <AsModal size="xl" state={openableState} close={close} title={translate('Upload Photo')}>
        <div className="h-96 w-full">
          <iframe className="h-full w-full" src={src} title="Fileslap" />
        </div>
      </AsModal>
    </>
  );
}

export default observer(FileslapFileUpload);

import { observer } from 'mobx-react';
import { ChangeEvent } from 'react';
import CountryRegionOptionsStore from '../../../../CountryRegionOptionsStore';
import { CountryRegionOption } from '../../../../CountryRegionOptionsStore/Types';
import ProfileFormStore from '../../../../ProfileFormStore';
import Label from '../../../Form/Label';
import Select from '../../../Form/Select';
import { convertArrayToOptions } from '../SelectOptionHelpers';
import * as style from './style.module.css';

interface Props {
  countryRegionOptionsStore: CountryRegionOptionsStore;
  profileStore: ProfileFormStore;
}

const handleRegionSelect =
  (profileStore: ProfileFormStore, countryRegionOptions: CountryRegionOption[]) =>
  (event: ChangeEvent<HTMLSelectElement>) => {
    profileStore.profileFormResource.map((resource) =>
      profileStore.regionSelected(resource, countryRegionOptions, event.target.value),
    );
  };

const regionSelector: React.FC<Props> = ({ countryRegionOptionsStore, profileStore }) => (
  <>
    <Label
      className={style.regionSelectLabel}
      labelText="Region"
      htmlFor="region"
      required={true}
    />
    <div className={style.input}>
      <Select
        className={style.fakeSelect}
        value={profileStore.region.map((r) => r.id).getOrElseValue('')}
        onChange={handleRegionSelect(profileStore, countryRegionOptionsStore.countryRegionOptions)}
        name="region"
        id="region"
        options={convertArrayToOptions(countryRegionOptionsStore.countryRegionOptions)}
      />
    </div>
  </>
);

export default observer(regionSelector);

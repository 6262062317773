import { Maybe, nothing } from 'maybeasy';
import { observer } from 'mobx-react';
import { Params } from '.';
import Main from '../../BrowserSupport/Main';
import WithCurrentUser from '../../CurrentUser/Context/WithCurrentUser';
import { findLink } from '../../LinkyLinky';
import { lightTheme } from '../../Native/styles';
import NotificationSource from '../../NotificationSource';
import Notifications from '../../Notifications';
import { ProgramModuleProgressProvider } from '../../ProgramModuleProgress/Context';
import ProgramStore from '../../ProgramStore';
import SegmentStore from '../../SegmentStore';
import ThemeContext from '../../Themes/ThemeContext';
import SiteTitle from '../../Title';
import { TranslationsContext, translation } from '../../Translations';
import AutoLaunchable from '../AutoLaunchable';
import Footer from '../Footer';
import NavigationBar from '../Platform/NavigationBar';
import * as platformStyle from '../Platform/style.module.css';
import ProgramReactions from '../ProgramReactions';
import SegmentPanel from '../SegmentPanel';
import SegmentReactions from '../SegmentReactions';
import SkipNavLink from '../SkipNavLink';
import Toasts from '../Toasts';
import CancelRegistration from './CancelRegistration';
import SegmentContent from './SegmentContent';
import SegmentNavigation from './SegmentNavigation';
import ExitButton from './SegmentNavigation/ExitButton';
import Title from './Title';
import * as style from './style.module.css';

interface Props {
  segmentStore: SegmentStore;
  programStore: ProgramStore;
  params: Params;
  programIdM: Maybe<number>;
}

function FullSegment({ segmentStore, programStore, params, programIdM }: Props) {
  return (
    <>
      {segmentStore.title.map((t) => <SiteTitle title={t} />).getOrElseValue(<></>)}
      <TranslationsContext.Consumer>
        {(ts) => (
          <>
            <div className={platformStyle.containerFull}>
              <SkipNavLink />
              <Toasts teamsStore={nothing()} />
              <NavigationBar />
            </div>
            <div className="flex">
              <div className={platformStyle.container}>
                <Notifications />
                <AutoLaunchable />
                <Main id="main" className={style.container}>
                  <ThemeContext.Provider value={lightTheme}>
                    <ProgramModuleProgressProvider store={segmentStore}>
                      <div className="mb-3 flex flex-col gap-x-4 gap-y-2">
                        <Title title={segmentStore.title} />
                        <SegmentNavigation store={segmentStore} programStore={programStore} />
                      </div>
                      <div className={style.content}>
                        <SegmentContent store={segmentStore} />
                        <NotificationSource store={segmentStore} />
                      </div>
                      <div className="flex flex-col gap-x-4 gap-y-2 sm:flex-row">
                        {findLink('delete', programStore.programLinks)
                          .map((l) => (
                            <CancelRegistration cancelLink={l} programStore={programStore} />
                          ))
                          .orElse(() => programStore.whenRegistration.map(() => <span />))
                          .orElse(() =>
                            programIdM.map((programId) => <ExitButton programId={programId} />),
                          )
                          .getOrElseValue(<span />)}
                        <SegmentNavigation store={segmentStore} programStore={programStore} />
                      </div>
                    </ProgramModuleProgressProvider>
                  </ThemeContext.Provider>
                </Main>

                <Footer />
              </div>
              <aside
                className={platformStyle.supPanel}
                aria-label={translation('Segment Panel')(ts)}
              >
                <SegmentPanel segmentStore={segmentStore} programStore={programStore} />
              </aside>
              <SegmentReactions
                store={segmentStore}
                params={params}
                advanceHook={() => {}}
                fireImmediately={true}
              />
              <WithCurrentUser
                children={(currentUserResource) => (
                  <ProgramReactions
                    store={programStore}
                    currentUserResource={currentUserResource}
                  />
                )}
              />
            </div>
          </>
        )}
      </TranslationsContext.Consumer>
    </>
  );
}

export default observer(FullSegment);

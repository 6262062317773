import { byPayloadId, equals, find } from '@execonline-inc/collections';
import { emptyFragment } from '@execonline-inc/execonline-ui';
import { toTask, when } from '@execonline-inc/maybe-adapter';
import { useConst } from '@execonline-inc/react-hooks.private';
import { always, noop } from '@kofno/piper';
import { Maybe } from 'maybeasy';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { useCurrentUserContext } from '../../../CurrentUser';
import { findLinkBy } from '../../../Links';
import { ProgramModuleProgress } from '../../../ProgramModuleProgress';
import { ProgramModuleProgressProvider } from '../../../ProgramModuleProgress/Context';
import { programsStore } from '../../../ProgramsStore';
import ProgramStore from '../../../ProgramStore';
import { ProgramSegment } from '../../../ProgramStore/Types';
import SegmentStore from '../../../SegmentStore';
import { NavigableResource, SegmentResource } from '../../../SegmentStore/Types';
import { T } from '../../../Translations';
import ContentStates from '../../ActiveProgramOverview/ActiveCoachingProductOverview/Orientation/OrientationModal/ModalSegment/ContentStates';
import { AutoLaunchableStore } from '../../AutoLaunchable/AutoLaunchableStore';
import ProgramReactions from '../../ProgramReactions';
import SubmitAndAdvanceButton from '../../Segment/SegmentNavigation/NextAdvanceButton/SubmitAndAdvanceButton';
import SegmentReactions from '../../SegmentReactions';
import PreviousSegmentButton from './PreviousSegmentButton';

interface Props {
  currentSegment: ProgramSegment;
  autoLaunchableStore: AutoLaunchableStore;
}

function whenSegmentT(segment: Maybe<SegmentResource>) {
  return toTask<string, SegmentResource>('segment-error')(segment);
}

function whenPresentationStyleT(segment: SegmentResource) {
  return toTask<string, string>('presentation-style-message')(
    when(equals('Streamlined'), segment.payload.presentationStyle),
  );
}

function ModalSegment({ currentSegment, autoLaunchableStore }: Props) {
  const currentUser = useCurrentUserContext();
  const programStore = useConst(() => new ProgramStore());
  const segmentStore = useConst(() => new SegmentStore('Streamlined'));

  useEffect(() => {
    programsStore.resource
      .map(({ payload }) => payload.programs)
      .andThen(find(byPayloadId(currentSegment.programId)))
      .do(programStore.loading);
  }, [currentSegment.programId]);

  const nextSegmentPresent = (resource: NavigableResource) =>
    findLinkBy({ rel: 'next' }, resource.links);

  return (
    <ProgramModuleProgressProvider store={segmentStore}>
      <div className="mt-8">
        <ProgramModuleProgress />
      </div>
      <ContentStates store={segmentStore} />
      <div className="mt-5 flex justify-between">
        {programStore.programNavigationType
          .andThen((value) => when(equals('navigable'), value))
          .map(() => (
            <PreviousSegmentButton data-testid="previous-segment-button" store={segmentStore}>
              <T kind="Previous" />
            </PreviousSegmentButton>
          ))
          .getOrElse(emptyFragment)}
        <SubmitAndAdvanceButton
          data-testid="next-segment-button"
          className="ml-auto"
          store={segmentStore}
        >
          {segmentStore.segmentResource
            .andThen(({ payload }) => payload.navigableResource)
            .andThen(nextSegmentPresent)
            .map(always(<T kind="Next" />))
            .getOrElse(always(<T kind="Finish" />))}
        </SubmitAndAdvanceButton>
      </div>
      <SegmentReactions
        params={{
          programId: String(currentSegment.programId),
          moduleId: String(currentSegment.moduleId),
          segmentId: String(currentSegment.id),
        }}
        store={segmentStore}
        advanceHook={(nextSegment: Maybe<SegmentResource>) => {
          whenSegmentT(nextSegment)
            .andThen(whenPresentationStyleT)
            .fork(autoLaunchableStore.load, noop);
        }}
        fireImmediately={true}
      />
      <ProgramReactions store={programStore} currentUserResource={currentUser} fireImmediately />
    </ProgramModuleProgressProvider>
  );
}

export default observer(ModalSegment);

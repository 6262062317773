import { observer } from 'mobx-react';
import { LeadershipPersonaSelectFormFieldOption } from '../../../Types';
import { emptyFragment, SlantedStarIcon } from '@execonline-inc/execonline-ui';

interface Props {
  option: LeadershipPersonaSelectFormFieldOption;
}
const PersonaTraitIcon = ({ option }: Props) =>
  option.resource
    .map((resource) => {
      const viewbox = '0 0 14 15';
      const size = 'inherit';
      switch (resource.payload.kind) {
        case 'adaptive-alex':
          return <SlantedStarIcon color="blue" viewBox={viewbox} size={size} />;
        case 'collaborative-charlie':
          return <SlantedStarIcon color="red" viewBox={viewbox} size={size} />;
        case 'decisive-darcy':
          return <SlantedStarIcon color="amber" viewBox={viewbox} size={size} />;
        case 'empathetic-elliot':
          return <SlantedStarIcon color="green" viewBox={viewbox} size={size} />;
        case 'visionary-vinny':
          return <SlantedStarIcon color="teal" viewBox={viewbox} size={size} />;
      }
    })
    .getOrElse(emptyFragment);

export default observer(PersonaTraitIcon);

import { Maybe } from 'maybeasy';
import { Link } from '../Resource/Types';

export interface AvatarLink {
  kind: 'avatar-link';
  link: Link;
}

export interface AvatarInitials {
  kind: 'avatar-initials';
  initials: string;
}

export interface AvatarClearing {
  kind: 'avatar-clearing';
  avatarLink: AvatarLink;
}

export type Avatar = AvatarLink | AvatarInitials | AvatarClearing;

export const avatarFrom = (link: Maybe<Link>, initials: Maybe<string>, initialsFallback = 'NA') =>
  link
    .map<Avatar>(avatarLink)
    .orElse(() => initials.map(avatarInitials))
    .getOrElse(() => avatarInitials(initialsFallback));

export const avatarLink = (link: Link): AvatarLink => ({
  kind: 'avatar-link',
  link,
});

export const avatarInitials = (initials: string): AvatarInitials => ({
  kind: 'avatar-initials',
  initials,
});

export const replaceAvatarLink =
  (avatar: Maybe<Link>) =>
  (links: ReadonlyArray<Link>): ReadonlyArray<Link> => {
    const newLinks = links.filter((ls) => ls.rel !== 'avatar');
    return avatar.cata({
      Just: (l) => newLinks.concat(l),
      Nothing: () => newLinks,
    });
  };

export function avatarHref(avatar: AvatarLink | AvatarClearing) {
  return avatar.kind === 'avatar-clearing' ? avatar.avatarLink.link.href : avatar.link.href;
}

import { Maybe } from 'maybeasy';
import { NonEmptyList } from 'nonempty-list';
import { CountryOption } from '../CountryOptionsStore/Types';
import CountryRegionOptionsStore from '../CountryRegionOptionsStore';
import { CountryRegionOption } from '../CountryRegionOptionsStore/Types';
import { Error } from '../ErrorHandling';
import { TimeZone, WorkPhone } from '../ProfileStore/Types';
import { Resource, ResourceWithErrors, ServerError } from '../Resource/Types';
import {
  SupportedLanguageCode,
  SupportedLanguageEnglishName,
  SupportedLanguageLocalName,
} from '../SupportedLanguages/Types';
import { TPlainTextKey } from '../Translations';

export interface Loading {
  kind: 'loading';
}

export interface Waiting {
  kind: 'waiting';
}

export interface UpdateSuccess {
  kind: 'update-success';
  profileFormResource: ProfileFormResource;
  flashMessage: Maybe<TPlainTextKey>;
  selectedLanguageCode: SupportedLanguageCode;
}

export interface Loaded {
  kind: 'loaded';
  profileFormResource: ProfileFormResource;
  flashMessage: Maybe<TPlainTextKey>;
  selectedLanguageCode: SupportedLanguageCode;
}

export interface ReadyWithErrors {
  kind: 'ready-with-errors';
  profileFormResource: ProfileFormResourceWithErrors;
  selectedLanguageCode: SupportedLanguageCode;
  countryRegionOptionsStore: CountryRegionOptionsStore;
  errors: ReadonlyArray<ServerError>;
}

export interface ProfileError extends Error {
  errors: ReadonlyArray<ServerError>;
  profileFormResource: ProfileFormResource;
  selectedLanguageCode: SupportedLanguageCode;
  countryRegionOptionsStore: CountryRegionOptionsStore;
  requiredFieldErrors: RequiredFieldErrors;
}

export interface LoadingCountries {
  kind: 'loading-countries';
  profileFormResource: ProfileFormResource;
  selectedLanguageCode: SupportedLanguageCode;
}

export interface CountriesLoaded {
  kind: 'countries-loaded';
  profileFormResource: ProfileFormResource;
  selectedLanguageCode: SupportedLanguageCode;
}

export interface SelectingCountry {
  kind: 'selecting-country';
  profileFormResource: Maybe<ProfileFormResource>;
  selectedLanguageCode: SupportedLanguageCode;
  event: React.FormEvent<HTMLSelectElement>;
  countries: NonEmptyList<CountryOption>;
}

export interface CountrySelected {
  kind: 'country-selected';
  profileFormResource: ProfileFormResource;
  selectedLanguageCode: SupportedLanguageCode;
}

export interface LoadingRegions {
  kind: 'loading-regions';
  countryId: Maybe<string>;
  profileFormResource: ProfileFormResource;
  selectedLanguageCode: SupportedLanguageCode;
}

export interface RegionsLoaded {
  kind: 'regions-loaded';
  profileFormResource: ProfileFormResource;
  selectedLanguageCode: SupportedLanguageCode;
}

export interface ReadyWithNoCountries {
  kind: 'ready-with-no-countries';
  profileFormResource: ProfileFormResource;
  selectedLanguageCode: SupportedLanguageCode;
}

export interface RegionSelected {
  kind: 'region-selected';
  profileFormResource: ProfileFormResource;
  selectedLanguageCode: SupportedLanguageCode;
}

export interface Updating {
  kind: 'updating';
  profileFormResource: ProfileFormResource;
  selectedLanguageCode: SupportedLanguageCode;
  errors: ReadonlyArray<ServerError>;
}

export interface Succeed {
  kind: 'succeed';
  profileFormResource: ProfileFormResource;
  selectedLanguageCode: SupportedLanguageCode;
}

export interface CheckingRequiredFields {
  kind: 'checking-required-fields';
  profileFormResource: ProfileFormResource;
  selectedLanguageCode: SupportedLanguageCode;
  requiredFieldErrors: RequiredFieldErrors;
}

export const loading = (): Loading => ({
  kind: 'loading',
});

export const waiting = (): Waiting => ({
  kind: 'waiting',
});

export const updateSuccess = (
  profileFormResource: ProfileFormResource,
  flashMessage: Maybe<TPlainTextKey>,
  selectedLanguageCode: SupportedLanguageCode,
): UpdateSuccess => ({
  kind: 'update-success',
  profileFormResource,
  flashMessage,
  selectedLanguageCode,
});

export const loaded = (
  profileFormResource: ProfileFormResource,
  flashMessage: Maybe<TPlainTextKey>,
  selectedLanguageCode: SupportedLanguageCode,
): Loaded => ({
  kind: 'loaded',
  profileFormResource,
  flashMessage,
  selectedLanguageCode,
});

export const succeed = (
  profileFormResource: ProfileFormResource,
  selectedLanguageCode: SupportedLanguageCode,
): Succeed => ({
  kind: 'succeed',
  profileFormResource,
  selectedLanguageCode,
});

export const readyWithErrors = (
  profileFormResource: ProfileFormResourceWithErrors,
  selectedLanguageCode: SupportedLanguageCode,
  countryRegionOptionsStore: CountryRegionOptionsStore,
  errors: ReadonlyArray<ServerError>,
): ReadyWithErrors => ({
  kind: 'ready-with-errors',
  profileFormResource,
  selectedLanguageCode,
  countryRegionOptionsStore,
  errors,
});

export const profileError = (
  message: TPlainTextKey,
  profileFormResource: ProfileFormResource,
  selectedLanguageCode: SupportedLanguageCode,
  countryRegionOptionsStore: CountryRegionOptionsStore,
  errors: ReadonlyArray<ServerError>,
  requiredFieldErrors: RequiredFieldErrors,
): ProfileError => ({
  kind: 'error',
  message,
  errors,
  profileFormResource,
  selectedLanguageCode,
  countryRegionOptionsStore,
  requiredFieldErrors,
});

export const loadingCountries = (
  profileFormResource: ProfileFormResource,
  selectedLanguageCode: SupportedLanguageCode,
): LoadingCountries => ({
  kind: 'loading-countries',
  profileFormResource,
  selectedLanguageCode,
});

export const countriesLoaded = (
  profileFormResource: ProfileFormResource,
  selectedLanguageCode: SupportedLanguageCode,
): CountriesLoaded => ({
  kind: 'countries-loaded',
  profileFormResource,
  selectedLanguageCode,
});

export const selectingCountry = (
  profileFormResource: Maybe<ProfileFormResource>,
  selectedLanguageCode: SupportedLanguageCode,
  event: React.FormEvent<HTMLSelectElement>,
  countries: NonEmptyList<CountryOption>,
): SelectingCountry => ({
  kind: 'selecting-country',
  profileFormResource,
  selectedLanguageCode,
  event,
  countries,
});

export const countrySelected = (
  profileFormResource: ProfileFormResource,
  selectedLanguageCode: SupportedLanguageCode,
): CountrySelected => ({
  kind: 'country-selected',
  profileFormResource,
  selectedLanguageCode,
});

export const loadingRegions = (
  countryId: Maybe<string>,
  profileFormResource: ProfileFormResource,
  selectedLanguageCode: SupportedLanguageCode,
): LoadingRegions => ({
  kind: 'loading-regions',
  countryId,
  profileFormResource,
  selectedLanguageCode,
});

export const regionsLoaded = (
  profileFormResource: ProfileFormResource,
  selectedLanguageCode: SupportedLanguageCode,
): RegionsLoaded => ({
  kind: 'regions-loaded',
  profileFormResource,
  selectedLanguageCode,
});

export const readyWithNoCountries = (
  profileFormResource: ProfileFormResource,
  selectedLanguageCode: SupportedLanguageCode,
): ReadyWithNoCountries => ({
  kind: 'ready-with-no-countries',
  profileFormResource,
  selectedLanguageCode,
});

export const regionSelected = (
  profileFormResource: ProfileFormResource,
  selectedLanguageCode: SupportedLanguageCode,
): RegionSelected => ({
  kind: 'region-selected',
  profileFormResource,
  selectedLanguageCode,
});

export const updating = (
  profileFormResource: ProfileFormResource,
  selectedLanguageCode: SupportedLanguageCode,
  errors: ReadonlyArray<ServerError>,
): Updating => ({
  kind: 'updating',
  profileFormResource,
  selectedLanguageCode,
  errors,
});

export const checkingRequiredFields = (
  profileFormResource: ProfileFormResource,
  selectedLanguageCode: SupportedLanguageCode,
  requiredFieldErrors: RequiredFieldErrors,
): CheckingRequiredFields => ({
  kind: 'checking-required-fields',
  profileFormResource,
  selectedLanguageCode,
  requiredFieldErrors,
});

export type ProfileState =
  | Waiting
  | Loading
  | Loaded
  | UpdateSuccess
  | ReadyWithErrors
  | ReadyWithNoCountries
  | LoadingCountries
  | CountriesLoaded
  | SelectingCountry
  | CountrySelected
  | LoadingRegions
  | RegionsLoaded
  | RegionSelected
  | Updating
  | CheckingRequiredFields
  | Succeed
  | ProfileError;

export interface Language {
  englishName: SupportedLanguageEnglishName;
  localName: SupportedLanguageLocalName;
  code: SupportedLanguageCode;
}

export type LanguageResource = Resource<Language>;

export type LanguagesResource = Resource<LanguageResource[]>;

export type Salutation = 'Mr.' | 'Ms.' | 'Dr.' | 'Mx.';

export interface LanguageOption {
  label: SupportedLanguageLocalName;
  value: SupportedLanguageCode;
}

export interface Country {
  name: string;
  id: string;
}

export type CountryResource = Resource<Country>;

export interface ProfileForm {
  firstName: Maybe<string>;
  lastName: Maybe<string>;
  shortName: Maybe<string>;
  defaultLandingPage: Maybe<LandingPage>;
  division: Maybe<string>;
  cellPhone: Maybe<string>;
  timeZone: Maybe<TimeZone>;
  workPhone: WorkPhone;
  currentPosition: Maybe<string>;
  organization: Maybe<string>;
  country: Maybe<CountryOption>;
  region: Maybe<CountryRegionOption>;
  salutation: Maybe<Salutation>;
  facebookPage: Maybe<string>;
  linkedInPage: Maybe<string>;
  twitterPage: Maybe<string>;
  timeZoneOptions: TimeZone[];
  preferredLanguageResource: Maybe<LanguageResource>;
  availableLanguageResource: LanguagesResource;
  defaultLandingPages: LandingPageOption[];
  salutationOptions: Salutation[];
}

export type CountryOptionsResource = Resource<Country>;

export type ProfileFormResource = Resource<ProfileForm>;
export type ProfileFormResourceWithErrors = ResourceWithErrors<ProfileForm>;

export interface CountryRegionOptions {
  countryRegionOptions: CountryRegionOption[];
}

export type CountryRegionOptionsResource = Resource<CountryRegionOption[]>;
export type CountryRegionOptionsResourceWithErrors = ResourceWithErrors<CountryRegionOption[]>;

export interface UserInputError {
  kind:
    | 'region-id-missing-error'
    | 'region-missing-error'
    | 'region-invalid-error'
    | 'first-name-missing-error'
    | 'last-name-missing-error'
    | 'salutation-error';
}

export type UserInputErrorMessage =
  | 'Add your first name'
  | 'Add your last name'
  | 'Select a region'
  | 'Please select a salutation from the list';

export type RequiredFieldErrors = ReadonlyArray<Maybe<UserInputErrorMessage>>;

export interface LandingPageOption {
  value: LandingPage;
}

export type LandingPage = 'learning-platform' | 'enterprise-portal' | 'client-portal';

import { equals } from '@execonline-inc/collections';
import { emptyFragment } from '@execonline-inc/execonline-ui';
import { when } from '@execonline-inc/maybe-adapter';
import { Maybe } from 'maybeasy';
import { observer } from 'mobx-react';
import * as React from 'react';
import { ProgramModuleProgress } from '../../../../../../ProgramModuleProgress';
import { ProgramModuleProgressProvider } from '../../../../../../ProgramModuleProgress/Context';
import ProgramStore from '../../../../../../ProgramStore';
import SegmentStore from '../../../../../../SegmentStore';
import { SegmentResource } from '../../../../../../SegmentStore/Types';
import { T } from '../../../../../../Translations';
import PreviousSegmentButton from '../../../../../AutoLaunchableModal/ModalSegment/PreviousSegmentButton';
import ModalStore from '../../../../../Modal/Store';
import SubmitAndAdvanceButton from '../../../../../Segment/SegmentNavigation/NextAdvanceButton/SubmitAndAdvanceButton';
import SegmentReactions from '../../../../../SegmentReactions';
import ContentStates from './ContentStates';

interface Props {
  programStore: ProgramStore;
  modalStore: ModalStore;
}

class ModalSegment extends React.Component<Props, {}> {
  segmentStore = new SegmentStore('Streamlined');

  render() {
    const { programStore, modalStore } = this.props;

    return programStore.currentSegment
      .map((currentSegment) => {
        return (
          <ProgramModuleProgressProvider store={this.segmentStore}>
            <div className="mt-8">
              <ProgramModuleProgress />
            </div>
            <ContentStates store={this.segmentStore} />
            <div className="mt-5 flex justify-between">
              {programStore.programNavigationType
                .andThen((value) => when(equals('navigable'), value))
                .map(() => (
                  <PreviousSegmentButton
                    data-testid="previous-segment-button"
                    store={this.segmentStore}
                  >
                    <T kind="Previous" />
                  </PreviousSegmentButton>
                ))
                .getOrElse(emptyFragment)}
              <SubmitAndAdvanceButton
                data-testid="next-segment-button"
                className="ml-auto"
                store={this.segmentStore}
              >
                <T kind="Next" />
              </SubmitAndAdvanceButton>
            </div>
            <SegmentReactions
              params={{
                programId: String(currentSegment.programId),
                moduleId: String(currentSegment.moduleId),
                segmentId: String(currentSegment.id),
              }}
              store={this.segmentStore}
              advanceHook={(nextSegment: Maybe<SegmentResource>) => {
                programStore.programDetailResource.map(programStore.reloading);
                nextSegment.elseDo(modalStore.close);
              }}
              fireImmediately={true}
            />
          </ProgramModuleProgressProvider>
        );
      })
      .getOrElse(emptyFragment);
  }
}

export default observer(ModalSegment);

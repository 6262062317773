import {
  PickerDisplayMode,
  PickerFileMetadata,
  PickerOptions,
  PickerResponse,
} from 'filestack-js/build/main/lib/picker';
import ReactFilestack from 'filestack-react';
import { observer } from 'mobx-react';
import { fromArray } from 'nonempty-list';
import * as React from 'react';
import AttachmentUploadStore from '../../../AttachmentUploadStore';
import { CurrentUserResource } from '../../../CurrentUser/Types';
import { languageTag } from '../../../LanguageParser';
import { acceptedFileExtensions } from '../../../SimpleFileUploadStore/Types';
import { toPromise } from '../../../TaskExt';
import { T } from '../../../Translations';
import { Upload } from '../../../Uploads';
import { escapeFilenameForUpload } from './Types';
import * as style from './style.module.css';

interface Props {
  store: AttachmentUploadStore;
  currentUserResource: CurrentUserResource;
}

class AdvancedFileUpload extends React.Component<Props, {}> {
  uploadSuccessful = (res: PickerResponse) => {
    fromArray(
      res.filesUploaded.map<Upload>((file: PickerFileMetadata) => {
        return {
          key: 'filestack-upload',
          handle: file.handle,
          filename: file.filename,
          uploadFilePath: `/${file.key}`,
          temporaryCdnUrl: file.url,
        };
      }),
    ).do(this.props.store.uploadSuccessful);
  };

  onFileSelected = (file: PickerFileMetadata) => toPromise(escapeFilenameForUpload(file));

  uploadFailed = (files: PickerFileMetadata[]) => {
    this.props.store.error('Your upload could not be processed at this time');
  };

  render() {
    const options: PickerOptions = {
      storeTo: {
        path: 'uploads/temp-uploads/fs/',
        region: process.env.REACT_APP_AWS_REGION,
        access: 'private',
      },
      displayMode: PickerDisplayMode.overlay,
      dropPane: {},
      container: '.attachment-upload',
      maxFiles: 5,
      fromSources: ['local_file_system'],
      accept: acceptedFileExtensions,
      uploadInBackground: false,
      onFileSelected: this.onFileSelected,
      lang: this.props.currentUserResource.payload.preferredLanguage
        .andThen(languageTag)
        .getOrElseValue('en'),
    };

    return (
      <div data-test-attachment-uploader={true}>
        <ReactFilestack
          apikey={process.env.REACT_APP_FILESTACK_KEY}
          options={options}
          onSuccess={this.uploadSuccessful}
          buttonText={<T kind="Pick file" />}
          onError={this.uploadFailed}
          buttonClass={style.pickFileButton}
          cname="upload.execonline.com"
        />
      </div>
    );
  }
}

export default observer(AdvancedFileUpload);

import { emptyFragment } from '@execonline-inc/execonline-ui';
import { observer } from 'mobx-react';
import ProgressBar from '../components/ProgressBar';
import useProgramModuleProgressContext from './hooks/useProgramModuleProgressContext';

function ProgramModuleProgress() {
  const progress = useProgramModuleProgressContext();

  return progress
    .map(({ payload }) => (
      <ProgressBar total={payload.totalCount} currentStep={payload.currentPosition} />
    ))
    .getOrElse(emptyFragment);
}

export default observer(ProgramModuleProgress);

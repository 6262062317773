import { observer } from 'mobx-react';
import * as React from 'react';
import { Avatar as AvatarData } from '../../../Avatar';
import * as style from '../style.module.css';
import Avatar from './Avatar';

interface Props {
  avatar: AvatarData;
}

const Photo: React.FC<Props> = ({ avatar }) => (
  <div className={style.profileAvatar} data-test-popout-photo={true}>
    <Avatar avatar={avatar} />
  </div>
);

export default observer(Photo);

import { observer } from 'mobx-react';
import * as React from 'react';
import AttachmentUploadStore from '../../../AttachmentUploadStore';
import { NotTranslated, T } from '../../../Translations';
import { Upload, uploadFilename, uploadHandle, Uploads } from '../../../Uploads';
import DeleteIcon from '../../Cutesies/DeleteIcon';
import * as style from './style.module.css';

interface Props {
  store: AttachmentUploadStore;
}

const UploadsToSubmit: React.FC<Props> = ({ store }) => (
  <div data-test-uploads-to-submit={true}>
    {store.uploadsToSubmit
      .map((uploads: Uploads) => (
        <>
          {uploads
            .map((upload: Upload) => (
              <li
                className={style.item}
                key={uploadHandle(upload)}
                data-test-upload={uploadFilename(upload)}
              >
                <NotTranslated text={uploadFilename(upload)} />
                <a
                  href="#delete"
                  onClick={() => store.removingUpload(upload)}
                  data-test-action-delete
                  className={style.deleteLink}
                >
                  <div className={style.deleteIcon}>
                    <DeleteIcon />
                  </div>
                </a>
              </li>
            ))
            .toArray()}
        </>
      ))
      .getOrElseValue(
        <div data-test-no-uploads-title="Select a file to submit">
          <T kind="Select a file to submit" />
        </div>,
      )}
  </div>
);

export default observer(UploadsToSubmit);

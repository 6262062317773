import { emptyFragment } from '@execonline-inc/execonline-ui';
import { noop } from '@kofno/piper';
import clsx from 'clsx';
import { observer } from 'mobx-react';
import { Params } from '.';
import Main from '../../BrowserSupport/Main';
import WithCurrentUser from '../../CurrentUser/Context/WithCurrentUser';
import { lightTheme } from '../../Native/styles';
import NotificationSource from '../../NotificationSource';
import Notifications from '../../Notifications';
import ProgramStore from '../../ProgramStore';
import SegmentStore from '../../SegmentStore';
import ThemeContext from '../../Themes/ThemeContext';
import SiteTitle from '../../Title';
import { TranslationsContext } from '../../Translations';
import AutoLaunchable from '../AutoLaunchable';
import * as platformStyle from '../Platform/style.module.css';
import ProgramReactions from '../ProgramReactions';
import SegmentReactions from '../SegmentReactions';
import SegmentContent from './SegmentContent';
import SegmentNavigation from './SegmentNavigation';
import Title from './Title';
import * as style from './style.module.css';

interface Props {
  segmentStore: SegmentStore;
  programStore: ProgramStore;
  params: Params;
}

function EmbeddedStreamlinedSegment({ segmentStore, programStore, params }: Props) {
  return (
    <>
      {segmentStore.title.map((t) => <SiteTitle title={t} />).getOrElse(emptyFragment)}
      <TranslationsContext.Consumer>
        {(ts) => (
          <div>
            <div className={platformStyle.container}>
              <Notifications />
              <AutoLaunchable />
              <Main id="main" className={clsx(style.container, style.embeddedContainer)}>
                <ThemeContext.Provider value={lightTheme}>
                  <div className={style.header}>
                    <Title title={segmentStore.title} />
                  </div>
                  <div className={style.content}>
                    <SegmentContent store={segmentStore} />
                    <NotificationSource store={segmentStore} />
                  </div>
                  <div className={style.footer}>
                    <SegmentNavigation store={segmentStore} programStore={programStore} />
                  </div>
                </ThemeContext.Provider>
              </Main>
            </div>
            <SegmentReactions
              store={segmentStore}
              params={params}
              advanceHook={noop}
              fireImmediately={true}
            />
            <WithCurrentUser
              children={(currentUserResource) => (
                <ProgramReactions store={programStore} currentUserResource={currentUserResource} />
              )}
            />
          </div>
        )}
      </TranslationsContext.Consumer>
    </>
  );
}
export default observer(EmbeddedStreamlinedSegment);

import { warn } from '@execonline-inc/logging';
import { observer } from 'mobx-react';
import { NonEmptyList } from 'nonempty-list';
import * as React from 'react';
import ProfileFormStore from '../../../ProfileFormStore';
import { parseSupportedLanguageCode } from '../../../ProfileFormStore/Decoders';
import { LanguageOption, LanguageResource } from '../../../ProfileFormStore/Types';
import { Link } from '../../../Resource/Types';
import { SupportedLanguageCode } from '../../../SupportedLanguages/Types';
import Select from '../../Form/Select';
import { SelectOption } from '../../Form/Select/Option';
import * as style from './style.module.css';

interface Props {
  languageSwitcher: SupportedLanguageCode;
  updateLink: Link;
  languageSwitcherOptions: NonEmptyList<LanguageOption>;
  profileStore: ProfileFormStore;
}

class Switcher extends React.Component<Props> {
  handleLanguageChange =
    (store: ProfileFormStore) => (event?: React.FormEvent<HTMLSelectElement>) => {
      if (event) {
        parseSupportedLanguageCode(event.currentTarget.value).fork(warn, (value) =>
          store.setLanguageCode(value),
        );
      }
    };

  convertToOptions = (options: LanguageResource[]): SelectOption[] =>
    options.map((option) => ({ content: option.payload.localName, value: option.payload.code }));

  render() {
    const { profileStore } = this.props;
    return (
      <>
        <Select
          className={style.fakeSelect}
          value={this.props.languageSwitcher}
          onChange={this.handleLanguageChange(profileStore)}
          name="preferred language"
          id="preferred_language"
          options={this.convertToOptions(this.props.profileStore.availableLanguageOptions)}
        />
      </>
    );
  }
}

export default observer(Switcher);

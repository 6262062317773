import { assertNever, identity } from '@kofno/piper';
import { Reject, Resolve } from 'taskarian';
import { ProfileStore } from '.';
import { AppyError, deleteToApi } from '../Appy';
import { Avatar } from '../Avatar';
import { findLinkT, MissingLinkError } from '../LinkyLinky';
import ReactionComponent, { RCProps } from '../ReactionComponent';

interface Props extends RCProps<ProfileStore> {}

export class AvatarReactions extends ReactionComponent<ProfileStore, Avatar, Props> {
  tester = () => this.props.store.avatar;
  effect = (avatar: Avatar): void => {
    const { store } = this.props;

    switch (avatar.kind) {
      case 'avatar-initials':
      case 'avatar-link':
        break;
      case 'avatar-clearing':
        runDeleteAvatar(
          store,
          (err) => {
            console.error('[exo] Avatar delete failed. Restoring avatar...', err);
            store.setAvatar(avatar.avatarLink);
          },
          store.avatarCleared,
        );
        break;
      default:
        assertNever(avatar);
    }
  };
}

function runDeleteAvatar(
  store: ProfileStore,
  failedFn: Reject<MissingLinkError | AppyError>,
  successFn: Resolve<string>,
): void {
  switch (store.state.kind) {
    case 'ready':
      findLinkT('delete-avatar', store.state.resource.links)
        .mapError<MissingLinkError | AppyError>(identity)
        .andThen(deleteToApi)
        .fork(failedFn, successFn);
      break;
    case 'loading':
    case 'waiting':
    case 'refreshing':
    case 'errored':
      // This is a noop by design
      break;
    default:
      assertNever(store.state);
  }
}

import { emptyFragment } from '@execonline-inc/execonline-ui';
import { pick } from '@kofno/piper';
import { observer } from 'mobx-react';
import { findLinkBy } from '../../Links';
import ProfileFormStore from '../../ProfileFormStore';
import FileslapFileUpload from './FileslapFileUpload';

interface Props {
  buttonClass: string;
  formStore: ProfileFormStore;
}

function FileUpload({ buttonClass, formStore }: Props) {
  return findLinkBy({ rel: 'embed-upload' }, formStore.links)
    .map(pick('href'))
    .map((src) => (
      <FileslapFileUpload src={src} buttonClass={buttonClass} buttonText="add a photo" />
    ))
    .getOrElse(emptyFragment);
}

export default observer(FileUpload);

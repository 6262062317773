import { first } from '@execonline-inc/collections';
import { just, Maybe } from 'maybeasy';
import { Avatar } from '../Avatar';
import { ConferenceParticipant } from '../ConferenceRosterStore/Types';
import { Author } from '../ConversationStore/Types';
import { CountryOption } from '../CountryOptionsStore/Types';
import { CountryRegionOption } from '../CountryRegionOptionsStore/Types';
import { LearningPartner } from '../LearningPartnersStore/Types';
import { Professor } from '../ProfessorsStore/Types';
import { ProfileResource } from '../ProfileStore/Types';
import { Link } from '../Resource/Types';
import { TeamMember } from '../TeamsStore/Types';

export interface PersonState {
  photo: Maybe<Link>;
  person: Person;
  id: number;
}

export interface UserPerson {
  kind: 'user';
  profileResource: ProfileResource;
}

export interface ProfessorPerson {
  kind: 'professor';
  profileResource: Professor;
}

export interface TeamMemberPerson {
  kind: 'team-member';
  profileResource: TeamMember;
}

export interface LearningPartnerPerson {
  kind: 'learning-partner';
  profileResource: LearningPartner;
}

export interface ConferenceParticipantPerson {
  kind: 'conference-participant';
  profileResource: ConferenceParticipant;
}

export interface MessageAuthor {
  kind: 'message-author';
  profileResource: Author;
}

export type ParticipantType = 'Professor' | 'Student' | 'Coach' | 'Auditor' | 'Observer';

export type Person =
  | UserPerson
  | ProfessorPerson
  | MessageAuthor
  | TeamMemberPerson
  | LearningPartnerPerson
  | ConferenceParticipantPerson;

export const professorPerson = (profileResource: Professor): ProfessorPerson => {
  return { kind: 'professor', profileResource };
};

export const messageAuthor = (profileResource: Author): MessageAuthor => {
  return { kind: 'message-author', profileResource };
};

export const teamMemberPerson = (profileResource: TeamMember): TeamMemberPerson => {
  return { kind: 'team-member', profileResource };
};

export const learningPartnerPerson = (profileResource: LearningPartner): LearningPartnerPerson => {
  return { kind: 'learning-partner', profileResource };
};

export const conferenceParticipantPerson = (
  profileResource: ConferenceParticipant,
): ConferenceParticipantPerson => {
  return { kind: 'conference-participant', profileResource };
};

export interface PersonStoreContract {
  id: number;
  kind: Person['kind'];
  name: Maybe<string>;
  initials: Maybe<string>;
  email: Maybe<string>;
  linkedInPage: Maybe<string>;
  facebookPage: Maybe<string>;
  twitterPage: Maybe<string>;
  position: Maybe<string>;
  division: Maybe<string>;
  workPhone: Maybe<string>;
  extension: Maybe<string>;
  cellPhone: Maybe<string>;
  organization: Maybe<string>;
  shortName: Maybe<string>;
  avatar: Avatar;
  country: Maybe<CountryOption>;
  region: Maybe<CountryRegionOption>;
}

export const initial = (name: Maybe<string>): Maybe<string> =>
  name.map((n) => n.toUpperCase().split('')).andThen(first);

export const initials = (firstName: Maybe<string>, lastName: Maybe<string>): Maybe<string> => {
  const initials = [
    initial(firstName).getOrElseValue(''),
    initial(lastName).getOrElseValue(''),
  ].join('');
  return just(initials || 'ME');
};

import clsx from 'clsx';
import { observer } from 'mobx-react';
import { Avatar as AvatarData, avatarHref } from '../../../Avatar';
import { NotTranslated, translation, TranslationsContext } from '../../../Translations';
import Image from '../../Image';
import * as style from '../style.module.css';

interface Props {
  avatar: AvatarData;
}

function Avatar({ avatar }: Props) {
  switch (avatar.kind) {
    case 'avatar-link':
    case 'avatar-clearing':
      return (
        <TranslationsContext.Consumer>
          {(ts) => <Image src={avatarHref(avatar)} alt={translation('User Photo')(ts)} />}
        </TranslationsContext.Consumer>
      );
    case 'avatar-initials':
      return (
        <span className={clsx(style.profileInitials, 'bg-primary-500')}>
          <NotTranslated text={avatar.initials} />
        </span>
      );
  }
}

export default observer(Avatar);
